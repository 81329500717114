import { FC } from "react";
import { Col, Flex, Menu, MenuProps, Row } from "antd";
import useLanguage from "src/store/language";
import { useHotelsData } from "../context/HotelsData";
import { FilterContainer } from "../styles";
import { HotelsTabMenuKeys, HotelsViewMode } from "../models/enums";
import { Guard } from "src/components/Guard";
import {
  AddHotelPath,
  AddHotelResponderDetailPath,
  AddHotelResponderPath,
  AllHotelsPath,
} from "src/services/BasicInformation/baseGuardPath";

const Filter: FC = () => {
  const { words } = useLanguage();

  const {
    states: { viewMode, currentFilter },
    dispatches,
  } = useHotelsData();

  const items: MenuProps["items"] = [
    {
      label: (
        <Guard action={AllHotelsPath}>
          <Flex justify="center" align="center" gap={8}>
            <span className="material-symbols-outlined">table_chart</span>{" "}
            <span>{words.basicInfo.hotelsTabTable}</span>
          </Flex>
        </Guard>
      ),
      key: HotelsTabMenuKeys.Table,
    },
    {
      label: (
        <Guard
          action={[
            AddHotelPath,
            AddHotelResponderDetailPath,
            AddHotelResponderPath,
          ]}
        >
          <Flex justify="center" align="center" gap={8}>
            <span className="material-symbols-outlined">hotel</span>
            <span>{words.basicInfo.hotelsTabAdd}</span>
          </Flex>
        </Guard>
      ),
      key: HotelsTabMenuKeys.Add,
    },
  ];

  const onClick: MenuProps["onClick"] = (e) => {
    dispatches.setSelectedHotel(undefined);
    dispatches.setCurrentFilter(e.key);
  };

  return (
    <>
      {viewMode === HotelsViewMode.Menu ? (
        <FilterContainer>
          <Col span={24} className="p-[24px] bg-[#fff] mt-[24px]">
            <Row align="middle">
              <Col xs={24}>
                <Menu
                  onClick={onClick}
                  selectedKeys={[currentFilter]}
                  mode="horizontal"
                  items={items}
                />
              </Col>
            </Row>
          </Col>{" "}
        </FilterContainer>
      ) : null}
    </>
  );
};

export default Filter;
