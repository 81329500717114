import {
  AssignActionToRoleGuardPath,
  AssignRoleToUserGuardPath,
  BaseInfoAddCityPaths,
  BaseInfoAddCountryPaths,
  BaseInfoBrokerPaths,
  BaseInfoHotelsPaths,
  BaseInfoPlacesPaths,
  BaseInfoRoomsPaths,
  ManagementAddTourGuardPaths,
  ManagementAssignSimCardGuardPaths,
  ManagementCompanionLeaderGuardPaths,
  ManagementDomesticFlightGuardPaths,
  ManagementDriverGuardPaths,
  ManagementEditTourGuardPaths,
  ManagementFileGuardPaths,
  ManagementInsuranceAttachmentGuardPaths,
  ManagementInsuranceGuardPaths,
  ManagementLeaderGuardPaths,
  ManagementRoutesGuardPaths,
  ManagementRoutesPlacesGuardPaths,
  ManagementRoutesRoomsGuardPaths,
  ManagementSimCardGuardPaths,
  ManagementTouristInfoGuardPaths,
  ManagementTourTimeGuardPaths,
  ManagementTranslatorGuardPaths,
  RoleGuardPath,
  UserGuardPath,
} from "src/components/Guard/permissions";
import { useCheckPermission } from "src/hook/checkPermission";
import { ROUTES } from "src/models/enums/routes";
import { create } from "zustand";

type PageTitleKeys = keyof typeof ROUTES;
export type PageTitleKeysType = (typeof ROUTES)[PageTitleKeys];
export type IRoutesAccessed = Record<PageTitleKeys, boolean>;
interface IStore {
  routes: IRoutesAccessed;
  setRoutes: (routes: Partial<Record<PageTitleKeys, boolean>>) => void;
}

const useRoutesAccessStore = () => {
  const { checkRoutePermission } = useCheckPermission();

  const store = create<IStore>((set) => ({
    routes: {
      home: true,
      login: true,
      addTour: checkRoutePermission(ManagementAddTourGuardPaths),
      tourTable: true,
      managementTour: true,
      tourView: true,
      tourEdit: true,
      tourAdd: true,
      tourEditChinese: true,
      settings: true,
      CreateRole: checkRoutePermission(RoleGuardPath),
      AssignActionToRole: checkRoutePermission(AssignActionToRoleGuardPath),
      CreateUser: checkRoutePermission(UserGuardPath),
      AssignRoleToUser: checkRoutePermission(AssignRoleToUserGuardPath),
      basicInfo: true,
      country: checkRoutePermission(BaseInfoAddCountryPaths),
      city: checkRoutePermission(BaseInfoAddCityPaths),
      hotel: checkRoutePermission(BaseInfoHotelsPaths),
      car: true,
      places: checkRoutePermission(BaseInfoPlacesPaths),
      tourManagementTouristInfo: checkRoutePermission(
        ManagementTouristInfoGuardPaths
      ),
      viewTouristInfo: true,
      tourManagementRoute: checkRoutePermission(ManagementRoutesGuardPaths),
      domesticFlight: checkRoutePermission(ManagementDomesticFlightGuardPaths),
      tourManagementBasicInfo: checkRoutePermission(
        ManagementEditTourGuardPaths
      ),
      viewDomesticFlight: true,
      tourManagementHotel: true,
      tourManagementTime: checkRoutePermission(ManagementTourTimeGuardPaths),
      tourManagementSimcard: checkRoutePermission(ManagementSimCardGuardPaths),
      tourManagementInsurance: checkRoutePermission(
        ManagementInsuranceGuardPaths
      ),
      tourManagementDriver: checkRoutePermission(ManagementDriverGuardPaths),
      tourManagementTranslator: checkRoutePermission(
        ManagementTranslatorGuardPaths
      ),
      tourManagementSupervisor: true,
      notification: true,
      viewNotification: true,
      tourManagementFiles: checkRoutePermission(ManagementFileGuardPaths),
      rooms: checkRoutePermission(BaseInfoRoomsPaths),
      broker: checkRoutePermission(BaseInfoBrokerPaths),
      managementTourReport: true,
      tourManagementCompanionLeader: checkRoutePermission(
        ManagementCompanionLeaderGuardPaths
      ),
      tourManagementLeader: checkRoutePermission(ManagementLeaderGuardPaths),
      tourManagementAssignSimcard: checkRoutePermission(
        ManagementAssignSimCardGuardPaths
      ),
      routeRoom: checkRoutePermission(ManagementRoutesRoomsGuardPaths),
      routePlace: checkRoutePermission(ManagementRoutesPlacesGuardPaths),
      insuranceAttachment: checkRoutePermission(
        ManagementInsuranceAttachmentGuardPaths
      ),
    },
    setRoutes: (newRoutes) =>
      set((state) => ({ routes: { ...state.routes, ...newRoutes } })),
  }));
  return store.getState();
};

export default useRoutesAccessStore;
