import { Flex, Spin } from "antd";
import { FC } from "react";
import { IInsuranceDocument } from "src/services/TourManagement/models";
import useLanguage from "src/store/language";
import { useInsuranceAttachmentData } from "../../context";
import { Guard } from "src/components/Guard";
import {
  DeleteInsuranceAttachmentPath,
  DownloadInsuranceAttachmentPath,
  EditInsuranceAttachmentPath,
} from "src/services/TourManagement/guardPath";

interface ITableActionsProps {
  item: IInsuranceDocument;
}

const TableActions: FC<ITableActionsProps> = ({ item }) => {
  const { words } = useLanguage();

  const {
    states: { progress, mode },
    func: { handleDeleteId },
    requests: { downloadAttachFile },
    dispatches: { setSelectedDocument },
  } = useInsuranceAttachmentData();

  const handleSelectAssign = () => {
    setSelectedDocument(item);
  };

  return (
    <>
      <Flex justify="center" className="gap-[16px]">
        <Guard action={DownloadInsuranceAttachmentPath}>
          <div
            className="download-action"
            onClick={() => downloadAttachFile(item?.attachmentId || 0)}
          >
            <span className="material-icons text-[14px]">
              sim_card_download
            </span>
            <span>{words.global.downloadAction}</span>
          </div>
        </Guard>
        <Spin spinning={progress > 0 ? true : false} size="large" fullscreen />
        {mode === "add" || mode === "edit" ? (
          <>
            <Guard action={EditInsuranceAttachmentPath}>
              <div className="edit-action" onClick={() => handleSelectAssign()}>
                <span className="material-icons text-[#0C9D61]  text-[16px]">
                  edit_note
                </span>
                <span>{words.global.editAction}</span>
              </div>
            </Guard>
            <Guard action={DeleteInsuranceAttachmentPath}>
              <div
                className="delete-action"
                onClick={() => handleDeleteId(item.attachmentId)}
              >
                <span className="material-icons text-[14px]">
                  delete_forever
                </span>
                <span>{words.global.delete}</span>
              </div>
            </Guard>
          </>
        ) : null}
      </Flex>
    </>
  );
};

export default TableActions;
