import { FC } from "react";
import AddTourForm from "./components/AddTourForm";
import { AddTourContainer } from "./style";
import AddTourDataProvider from "./context";
import { Col } from "antd";
import { Guard } from "src/components/Guard";
import { AddTourPath } from "src/services/TourManagement/guardPath";

const AddTour: FC = () => {
  return (
    <Guard action={AddTourPath}>
      <AddTourDataProvider>
        <AddTourContainer justify={"center"} gutter={[0, 24]}>
          <Col span={24} className="content">
            <AddTourForm />
          </Col>
        </AddTourContainer>
      </AddTourDataProvider>
    </Guard>
  );
};
export default AddTour;
