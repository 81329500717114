import { FC } from "react";
import HotelsAdd from "./add";
import TableHotel from "./table";
import { useHotelsData } from "../context/HotelsData";
import { HotelsTabMenuKeys } from "../models/enums";
import { Guard } from "src/components/Guard";
import {
  AddHotelPath,
  AddHotelResponderDetailPath,
  AddHotelResponderPath,
  AllHotelsPath,
} from "src/services/BasicInformation/baseGuardPath";

const FilterContent: FC = () => {
  const { states } = useHotelsData()!;

  const handleFilterContent = () => {
    switch (states.currentFilter) {
      case HotelsTabMenuKeys.Add:
        return (
          <Guard
            action={[
              AddHotelPath,
              AddHotelResponderDetailPath,
              AddHotelResponderPath,
            ]}
          >
            <HotelsAdd />
          </Guard>
        );
      case HotelsTabMenuKeys.Table:
        return (
          <Guard action={AllHotelsPath}>
            <TableHotel />
          </Guard>
        );
    }
  };
  return <>{handleFilterContent()}</>;
};

export default FilterContent;
