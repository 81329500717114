import { FC } from "react";
import { Col, Row } from "antd";
import ToursList from "./components/List";
import TourTableDataProvider from "./context/TourTableData";
import TableHeader from "./components/TableHeader";

const TourTable: FC = () => {
  return (
    <TourTableDataProvider>
      <Row align={"middle"} justify={"center"}>
        <TableHeader />
        <Col span={23} className="pb-[32px]">
          <ToursList />
        </Col>
      </Row>
    </TourTableDataProvider>
  );
};

export default TourTable;
