import { IPath } from "src/models/interfaces/IPath";

export const AllApiPathBaseInfo: IPath[] = [
  {
    path: "api/BaseInfo/AddCountry",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllCountry",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{CountryId}/UpdateCountry",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{CountryId}/DeleteCountry",
    httpMethod: "DELETE",
  },
  {
    path: "BaseInfo/AddCity",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllCity",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{CityId}/UpdateCity",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{CityId}/DeleteCity",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/AddPlaceOfInterest",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllPlaceOfInterests",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{CityId}/GetPlaceOfInterestByCityId",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{PlaceOfInterestId}/UpdatePlaceOfInterest",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{PlaceOfInterestId}/DeletePlaceOfInterest",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/AddHotel",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllHotels",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{CityId}/AllHotelsByCity",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{HotelId}/UpdateHotel",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{HotelId}/DeleteHotel",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/{HotelId}/AddHotelResponder",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/{HotelId}/AllHotelResponders",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{HotelResponderId}/UpdateHotelResponder",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{HotelResponderId}/DeleteHotelResponder",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/{HotelResponderId}/AddHotelResponderDetail",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/{HotelId}/AllHotelResponderDetails",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{HotelResponderId}/HotelResponderDetailsById",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{HotelResponderDetailId}/UpdateHotelResponderDetail",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{HotelResponderDetailId}/DeleteHotelResponderDetail",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/AddCar",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllCar",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{CarId}/UpdateCar",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{CarId}/DeleteCar",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/AddRoom",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllRoom",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{RoomId}/UpdateRoom",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{RoomId}/DeleteRoom",
    httpMethod: "DELETE",
  },
  {
    path: "api/BaseInfo/AddBroker",
    httpMethod: "POST",
  },
  {
    path: "api/BaseInfo/AllBroker",
    httpMethod: "GET",
  },
  {
    path: "api/BaseInfo/{BrokerId}/UpdateBroker",
    httpMethod: "PUT",
  },
  {
    path: "api/BaseInfo/{BrokerId}/DeleteBroker",
    httpMethod: "DELETE",
  },
];

export const AddCountryPath: IPath = {
  path: "api/BaseInfo/AddCountry",
  httpMethod: "POST",
};

export const AllCountryPath: IPath = {
  path: "api/BaseInfo/AllCountry",
  httpMethod: "GET",
};

export const UpdateCountryPath: IPath = {
  path: "api/BaseInfo/{CountryId}/UpdateCountry",
  httpMethod: "PUT",
};

export const DeleteCountryPath: IPath = {
  path: "api/BaseInfo/{CountryId}/DeleteCountry",
  httpMethod: "DELETE",
};

export const AddCityPath: IPath = {
  path: "BaseInfo/AddCity",
  httpMethod: "POST",
};

export const AllCityPath: IPath = {
  path: "api/BaseInfo/AllCity",
  httpMethod: "GET",
};

export const UpdateCityPath: IPath = {
  path: "api/BaseInfo/{CityId}/UpdateCity",
  httpMethod: "PUT",
};

export const DeleteCityPath: IPath = {
  path: "api/BaseInfo/{CityId}/DeleteCity",
  httpMethod: "DELETE",
};

export const AddPlaceOfInterestPath: IPath = {
  path: "api/BaseInfo/AddPlaceOfInterest",
  httpMethod: "POST",
};

export const AllPlaceOfInterestsPath: IPath = {
  path: "api/BaseInfo/AllPlaceOfInterests",
  httpMethod: "GET",
};

export const GetPlaceOfInterestByCityIdPath: IPath = {
  path: "api/BaseInfo/{CityId}/GetPlaceOfInterestByCityId",
  httpMethod: "GET",
};

export const UpdatePlaceOfInterestPath: IPath = {
  path: "api/BaseInfo/{PlaceOfInterestId}/UpdatePlaceOfInterest",
  httpMethod: "PUT",
};

export const DeletePlaceOfInterestPath: IPath = {
  path: "api/BaseInfo/{PlaceOfInterestId}/DeletePlaceOfInterest",
  httpMethod: "DELETE",
};

export const AddHotelPath: IPath = {
  path: "api/BaseInfo/AddHotel",
  httpMethod: "POST",
};

export const AllHotelsPath: IPath = {
  path: "api/BaseInfo/AllHotels",
  httpMethod: "GET",
};

export const AllHotelsByCityPath: IPath = {
  path: "api/BaseInfo/{CityId}/AllHotelsByCity",
  httpMethod: "GET",
};

export const UpdateHotelPath: IPath = {
  path: "api/BaseInfo/{HotelId}/UpdateHotel",
  httpMethod: "PUT",
};

export const DeleteHotelPath: IPath = {
  path: "api/BaseInfo/{HotelId}/DeleteHotel",
  httpMethod: "DELETE",
};

export const AddHotelResponderPath: IPath = {
  path: "api/BaseInfo/{HotelId}/AddHotelResponder",
  httpMethod: "POST",
};

export const AllHotelRespondersPath: IPath = {
  path: "api/BaseInfo/{HotelId}/AllHotelResponders",
  httpMethod: "GET",
};

export const UpdateHotelResponderPath: IPath = {
  path: "api/BaseInfo/{HotelResponderId}/UpdateHotelResponder",
  httpMethod: "PUT",
};

export const DeleteHotelResponderPath: IPath = {
  path: "api/BaseInfo/{HotelResponderId}/DeleteHotelResponder",
  httpMethod: "DELETE",
};

export const AddHotelResponderDetailPath: IPath = {
  path: "api/BaseInfo/{HotelResponderId}/AddHotelResponderDetail",
  httpMethod: "POST",
};

export const AllHotelResponderDetailsPath: IPath = {
  path: "api/BaseInfo/{HotelId}/AllHotelResponderDetails",
  httpMethod: "GET",
};

export const HotelResponderDetailsByIdPath: IPath = {
  path: "api/BaseInfo/{HotelResponderId}/HotelResponderDetailsById",
  httpMethod: "GET",
};

export const UpdateHotelResponderDetailPath: IPath = {
  path: "api/BaseInfo/{HotelResponderDetailId}/UpdateHotelResponderDetail",
  httpMethod: "PUT",
};

export const DeleteHotelResponderDetailPath: IPath = {
  path: "api/BaseInfo/{HotelResponderDetailId}/DeleteHotelResponderDetail",
  httpMethod: "DELETE",
};

export const AddCarPath: IPath = {
  path: "api/BaseInfo/AddCar",
  httpMethod: "POST",
};

export const AllCarPath: IPath = {
  path: "api/BaseInfo/AllCar",
  httpMethod: "GET",
};

export const UpdateCarPath: IPath = {
  path: "api/BaseInfo/{CarId}/UpdateCar",
  httpMethod: "PUT",
};

export const DeleteCarPath: IPath = {
  path: "api/BaseInfo/{CarId}/DeleteCar",
  httpMethod: "DELETE",
};

export const AddRoomPath: IPath = {
  path: "api/BaseInfo/AddRoom",
  httpMethod: "POST",
};

export const AllRoomPath: IPath = {
  path: "api/BaseInfo/AllRoom",
  httpMethod: "GET",
};

export const UpdateRoomPath: IPath = {
  path: "api/BaseInfo/{RoomId}/UpdateRoom",
  httpMethod: "PUT",
};

export const DeleteRoomPath: IPath = {
  path: "api/BaseInfo/{RoomId}/DeleteRoom",
  httpMethod: "DELETE",
};

export const AddBrokerPath: IPath = {
  path: "api/BaseInfo/AddBroker",
  httpMethod: "POST",
};

export const AllBrokerPath: IPath = {
  path: "api/BaseInfo/AllBroker",
  httpMethod: "GET",
};

export const UpdateBrokerPath: IPath = {
  path: "api/BaseInfo/{BrokerId}/UpdateBroker",
  httpMethod: "PUT",
};

export const DeleteBrokerPath: IPath = {
  path: "api/BaseInfo/{BrokerId}/DeleteBroker",
  httpMethod: "DELETE",
};
