import { Flex } from "antd";
import { FC } from "react";
import { ITableRoutePlace } from "src/services/TourManagement/models";
import useLanguage from "src/store/language";
import { useRoutePlacesData } from "../../context";
import { Guard } from "src/components/Guard";
import {
  DeleteRoutePlaceOfInterestPath,
  EditRoutePlaceOfInterestPath,
} from "src/services/TourManagement/guardPath";

interface ITableActionsProps {
  item: ITableRoutePlace;
}

const TableActions: FC<ITableActionsProps> = ({ item }) => {
  const { words } = useLanguage();

  const {
    states: { routePlaceForm },
    func: { handleDeleteId },
    dispatches: { setSelectedRoutePlace, setPlacesParams },
  } = useRoutePlacesData();

  const handleSelectHotel = () => {
    routePlaceForm.setFieldValue("placeOfInterestId", null);
    setPlacesParams({ SearchQuery: item.cityName });
    setSelectedRoutePlace(item);
  };

  return (
    <>
      <Flex justify="center" align="center" className="gap-[16px]">
        <Guard action={EditRoutePlaceOfInterestPath}>
          <div className="edit-action" onClick={() => handleSelectHotel()}>
            <span className="material-icons text-[14px]">edit_note</span>
            <span>{words.global.editAction}</span>
          </div>
        </Guard>
        <Guard action={DeleteRoutePlaceOfInterestPath}>
          <div
            className="delete-action"
            onClick={() => handleDeleteId(item.routePlaceOfInterestId)}
          >
            <span className="material-icons text-[14px]">delete_forever</span>
            <span>{words.global.delete}</span>
          </div>
        </Guard>
      </Flex>
    </>
  );
};

export default TableActions;
