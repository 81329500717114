import { FC } from "react";
import { Col } from "antd";
import { ManagementTimeContainer } from "./style";
import { useManagementTimeData } from "./context";
import ViewTime from "./components/ViewTime";
import AddEditTime from "./components/AddEditTime";
import { Guard } from "src/components/Guard";
import {
  EditTourTimePath,
  GetTourTimePath,
} from "src/services/TourManagement/guardPath";

const ManagementTime: FC = () => {
  const {
    states: { mode },
  } = useManagementTimeData();

  return (
    <>
      {(mode === "add" || mode === "edit") && (
        <Guard action={[GetTourTimePath, EditTourTimePath]}>
          <ManagementTimeContainer justify={"center"} gutter={[0, 24]}>
            <Col xs={24} md={23} className="content">
              <AddEditTime />
            </Col>
          </ManagementTimeContainer>
        </Guard>
      )}
      {mode === "view" && (
        <ManagementTimeContainer justify={"center"} gutter={[0, 24]}>
          <Col xs={24} md={18} className="content">
            <ViewTime />
          </Col>
        </ManagementTimeContainer>
      )}
    </>
  );
};

export default ManagementTime;
