import { FC, useEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Skeleton } from "antd";
import { IdentityService } from "src/services/Identity/Identity.service";
import { ILoginArgs } from "src/services/Identity/models";
import { useLogin } from "src/hook";
import { LoginContainer } from "./styles";
import useLanguage from "src/store/language";

const Login: FC = () => {
  const { loginHandler } = useLogin();
  const [loading, setLoading] = useState<boolean>(false);
  const [captchaLoading, setCaptchaLoading] = useState<boolean>(true);
  const [captchaId, setCaptchaId] = useState<string>("");
  const [captchaImage, setCaptchaImage] = useState<string>("");

  const { words } = useLanguage();

  const [form] = Form.useForm();

  const getCaptcha = async () => {
    setCaptchaLoading(true);
    try {
      const { GetCaptcha } = new IdentityService();
      const res = await GetCaptcha();
      if (res && res.data) {
        setCaptchaId(res.data.captchaId);
        setCaptchaImage("data:png;base64," + res.data.image);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setCaptchaLoading(false);
    }
  };

  const onFinish = async (values: Omit<ILoginArgs, "captchaId">) => {
    try {
      setLoading(true);
      const reqBody: ILoginArgs = { ...values, captchaId };
      const { Login } = new IdentityService();
      const result = await Login(reqBody);
      if (result && result.data && result.status === 200) {
        loginHandler(
          result.data.accessToken,
          result.data.roles,
          result.data.paths
        );
      }
    } catch (err) {
      getCaptcha();
      form.setFieldValue("captchaValue", "");
    } finally {
      setLoading(false);
    }
  };
  let timerId: any;

  useEffect(() => {
    if (!timerId) getCaptcha();
  }, [timerId]);

  useEffect(() => {
    let time = 0;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    timerId = setInterval(() => {
      if (time === 200) {
        getCaptcha();
        time = 0;
      } else {
        time += 1;
      }
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);

  return (
    <LoginContainer justify={"center"} align={"middle"}>
      <Col xs={18} md={12} className="content">
        <Row gutter={[0, 16]}>
          <Col span={24} className="logo">
            <img src="/assets/images/logo-1.svg" alt="logo" />
          </Col>

          <Col span={24}>
            <Row align={"middle"} justify={"space-between"} gutter={[0, 0]}>
              <Col span={6}>
                {/* <Divider className="bg-[#273574] h-[2.2px] !w-[10px]" /> */}
              </Col>
              <Col span={11} className="title pb-[6px]">
                INTERNATIONAL
              </Col>
              <Col span={6}>
                {/* <Divider className="bg-[#273574] h-[2.2px]" /> */}
              </Col>
              <Col span={12} className="title-company">
                COMPANY
              </Col>
              <Col span={12} className="title-tourism">
                TOURISM
              </Col>
            </Row>
          </Col>

          <Col span={24} className="flex flex-col gap-[30px]">
            <Form wrapperCol={{ span: 24 }} form={form} onFinish={onFinish}>
              <Form.Item
                wrapperCol={{ span: 24 }}
                name={"userName"}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <Input
                  placeholder={words.login.username}
                  className="inputPassword "
                  onPressEnter={form.submit}
                  prefix={
                    <span className="material-icons text-[#1F1F1F]">
                      perm_identity
                    </span>
                  }
                  autoComplete="off"
                />
              </Form.Item>
              <Form.Item
                name={"password"}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <Input.Password
                  placeholder={words.login.password}
                  className="inputPassword "
                  onPressEnter={form.submit}
                  prefix={
                    <span className="material-icons text-[#1F1F1F]">
                      lock_person
                    </span>
                  }
                  autoComplete="off"
                />
              </Form.Item>

              <div className="!w-[100%] flex justify-between items-center">
                <div
                  // className={`w-[56px] h-[38px] bg-[#CACACA] flex justify-center items-center rounded-[6px] cursor-pointer select-none ml-1`}
                  className="refresh"
                  onClick={getCaptcha}
                >
                  <span className="material-icons text-[#1F1F1F] ">
                    refresh
                  </span>
                </div>
                {captchaLoading ? (
                  <Skeleton.Input className="!h-[45.6px] !w-[190px]" active />
                ) : (
                  <img className="captcha" src={captchaImage} alt="captcha" />
                )}
              </div>

              <Form.Item
                wrapperCol={{ span: 24 }}
                name={"captchaValue"}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
                className="mt-[24px]"
              >
                <Input
                  placeholder={words.login.captchaPlaceholder}
                  className="inputPassword "
                  onPressEnter={form.submit}
                  prefix={
                    <span className="material-icons text-[#1F1F1F]">
                      closed_caption
                    </span>
                  }
                  autoComplete="off"
                />
              </Form.Item>
              <Button
                className="button"
                htmlType="button"
                loading={loading}
                onClick={form.submit}
              >
                {words.login.action}
              </Button>
            </Form>
          </Col>
        </Row>
      </Col>
    </LoginContainer>
  );
};
export default Login;
