import { useNavigate } from "react-router-dom";
import { USER_ACCESS_PATH, USER_DATA, USER_JWT_TOKEN } from "src/constants";
import { ROUTES } from "src/models/enums/routes";
import { IPath } from "src/models/interfaces/IPath";
import useAuthStore from "src/store/auth";

export const useLogin = () => {
  const navigate = useNavigate();
  const { login, setUserRole, setAccessPath } = useAuthStore();

  const loginHandler = async (
    token: string,
    roles: string[],
    paths: IPath[]
  ) => {
    localStorage.setItem(USER_ACCESS_PATH, JSON.stringify(paths));
    localStorage.setItem(USER_JWT_TOKEN, token);
    localStorage.setItem(USER_DATA, JSON.stringify(roles));
    setAccessPath(paths);
    setUserRole(roles);
    login(token);
    navigate(ROUTES.home);
  };

  return { loginHandler };
};
