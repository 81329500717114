import { FC } from "react";
import { Col } from "antd";
import { ManagementFilesContainer } from "./style";
import { useManagementFilesData } from "./context";
import ManagementFilesTable from "./components/table";
import AddEditForm from "./components/form/AddEditForm";
import { Guard } from "src/components/Guard";
import {
  AddTourAttachmentPath,
  GetAllTourAttachmentPath,
} from "src/services/TourManagement/guardPath";

const ManagementFiles: FC = () => {
  const {
    states: { mode },
  } = useManagementFilesData();

  return (
    <>
      {(mode === "add" || mode === "edit") && (
        <Guard action={[AddTourAttachmentPath, GetAllTourAttachmentPath]}>
          <ManagementFilesContainer justify={"center"} gutter={[0, 24]}>
            <Col xs={24} md={23} className="content">
              <AddEditForm />
              <ManagementFilesTable />
            </Col>
          </ManagementFilesContainer>
        </Guard>
      )}
      {mode === "view" && (
        <ManagementFilesContainer justify={"center"} gutter={[0, 24]}>
          <Col xs={24} md={23} className="content">
            <ManagementFilesTable />
          </Col>
        </ManagementFilesContainer>
      )}
    </>
  );
};

export default ManagementFiles;
