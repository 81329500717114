import { FC, useEffect, useState } from "react";
import { AssignActionToRoleContainer } from "./styles";
import { Col, Flex, Input } from "antd";
import { TableUI } from "src/components/UiKit/table";
import { TableData, useAssignActionToRole } from "./context";
import { AssignActionModal } from "./components/AssignActionsModal";
import { getRowKey } from "src/helper/getRowKey";
import useLanguage from "src/store/language";
import { Guard } from "src/components/Guard";
import { AssignRoleToActionPath } from "src/services/System/guardPath";

export const AssignActionToRole: FC = () => {
  const {
    values: {
      tableColumns,
      dataSource,
      showAssign,
      allActionsQuery,
      treeData,
      currentCheckedKeys,
      loadingRolesOfAction,
      assignLoading,
    },
    func: { onCancelAssign, onAssign },
  } = useAssignActionToRole();
  const [data, setData] = useState<TableData[]>([]);
  const { words } = useLanguage();
  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);
  const onSearch = (value: string) => {
    if (!value) {
      setData(dataSource);
      return;
    }
    if (dataSource) {
      const newData = dataSource
        .filter(
          (item) =>
            item.displayName
              .toLowerCase()
              .trim()
              .includes(value.toLowerCase().trim()) ||
            item.name
              .toLowerCase()
              .trim()
              .includes(value.toLowerCase().trim()) ||
            item.urlPath
              .toLowerCase()
              .trim()
              .includes(value.toLowerCase().trim())
        )
        .map((item, i) => {
          item["#"] = i + 1;
          return item;
        });
      setData(newData);
    }
  };
  return (
    <Guard action={AssignRoleToActionPath}>
      <AssignActionToRoleContainer justify={"center"} gutter={[0, 24]}>
        <Col span={24} className="header">
          <Flex align="center" justify="space-between">
            <Flex justify="flex-start" align="center" gap={32}>
              <Input
                placeholder={
                  words.systemSettings.assignActionToRoleSearchPlaceholder
                }
                prefix={
                  <span className="material-icons text-[#1B2559]">search</span>
                }
                className="w-[341px] h-[46px] rounded-[10px]"
                onChange={(event) => {
                  const value = event.currentTarget.value;
                  onSearch(value);
                }}
                autoComplete="off"
              />
            </Flex>
          </Flex>
        </Col>
        <Col span={22} order={1} className="content">
          <TableUI
            columns={tableColumns}
            dataSource={data}
            loading={allActionsQuery?.isFetching}
            rowKey={getRowKey}
            // scroll={{ x: 800 }}
            tableType="secondary"
            pagination={{
              position: ["bottomCenter"],
            }}
          />
        </Col>
        <AssignActionModal
          open={showAssign}
          onCancel={onCancelAssign}
          title={words.systemSettings.assignActionToRoleModalTitle}
          treeData={treeData}
          onAssign={onAssign}
          currentCheckedKeys={currentCheckedKeys}
          loading={loadingRolesOfAction}
          confirmLoading={assignLoading}
          closable
        />
      </AssignActionToRoleContainer>
    </Guard>
  );
};
