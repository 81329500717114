import { FC } from "react";
import { Col } from "antd";
import { ManagementLeaderContainer } from "./style";
import { useManagementLeaderData } from "./context";
import ViewLeader from "./components/ViewLeader";
import AddEditLeader from "./components/AddEditLeader";
import { Guard } from "src/components/Guard";
import {
  AddLeaderPath,
  GetLeaderPath,
} from "src/services/TourManagement/guardPath";

const ManagementLeader: FC = () => {
  const {
    states: { mode },
  } = useManagementLeaderData();

  return (
    <>
      {(mode === "add" || mode === "edit") && (
        <Guard action={[GetLeaderPath, AddLeaderPath]}>
          <ManagementLeaderContainer justify={"center"} gutter={[0, 24]}>
            <Col xs={24} md={23} className="content">
              <AddEditLeader />
            </Col>
          </ManagementLeaderContainer>
        </Guard>
      )}
      {mode === "view" && (
        <ManagementLeaderContainer justify={"center"} gutter={[0, 24]}>
          <Col xs={24} md={23} className="content">
            <ViewLeader />
          </Col>
        </ManagementLeaderContainer>
      )}
    </>
  );
};

export default ManagementLeader;
