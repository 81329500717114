import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { reactQueryEndPoints, USER_ACCESS_PATH } from "src/constants";
import { IdentityService } from "src/services/Identity/Identity.service";
import useAuthStore from "src/store/auth";
import useGlobalStore from "src/store/global";

export const useUserInfo = (token?: string | undefined) => {
  const { setUserInfo } = useGlobalStore();
  const { setAccessPath } = useAuthStore();
  const [gotInfo, setGotInfo] = useState<boolean>(false);
  const fetchUserInfo = async () => {
    try {
      const { UserInfo } = new IdentityService();
      const res = await UserInfo();
      if (res && res.data) {
        const paths = res.data.paths;
        localStorage.setItem(USER_ACCESS_PATH, JSON.stringify(paths));
        setAccessPath(paths);
        setUserInfo && setUserInfo(res.data);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setGotInfo(true);
    }
  };
  const { refetch: userInfoRefetch } = useQuery({
    queryKey: reactQueryEndPoints.UserInfo,
    queryFn: fetchUserInfo,
    enabled: false,
  });
  useEffect(() => {
    if (token) userInfoRefetch();
    else setGotInfo(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfoRefetch, gotInfo]);
  return { userInfoRefetch, gotInfo };
};
