import { FC, useState } from "react";
import { Button, Col, Divider, Row } from "antd";
import SubmitButton from "src/components/UiKit/SubmitButton";
import useLanguage from "src/store/language";
import { useManagementDriverData } from "../../context";
import { useFormHasChanged } from "../../useFormHasChanged";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";
import { useManagementTourBack } from "src/hook/useManagementTourBack";
import { Guard } from "src/components/Guard";
import {
  AddDriverPath,
  DeleteDriverPath,
  EditDriverPath,
} from "src/services/TourManagement/guardPath";

const FormFooter: FC = () => {
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const { words } = useLanguage();

  const {
    states: { loading, mode, driverForm, deleteLoading },
    requests: { onDeleteDriver },
  } = useManagementDriverData();

  const canSubmit = useFormHasChanged();
  const { backHandler } = useManagementTourBack();

  const handleCancel = () => {
    if (mode === "add") {
      driverForm.resetFields();
    } else backHandler();
  };

  return (
    <>
      <Row gutter={[24, 12]}>
        <Divider className="bg-[#EEEEEE]" />
        <Col span={24} className="flex justify-between">
          <div>
            {mode === "edit" ? (
              <Guard action={DeleteDriverPath}>
                <Button
                  type="default"
                  className="delete-btn"
                  onClick={() => setShowDelete(true)}
                >
                  {words.global.delete}
                </Button>
              </Guard>
            ) : null}
          </div>
          <div className="flex justify-end">
            <Button
              type="text"
              className="bg-[#F5F5F5] text-[#8E8E8E] ml-2 rounded-lg w-[85px]"
              onClick={handleCancel}
            >
              {mode === "add"
                ? words.managementTranslator.clear
                : words.managementTranslator.cancel}
            </Button>
            <Guard action={EditDriverPath || AddDriverPath}>
              <SubmitButton
                form={driverForm}
                loading={loading}
                canSubmit={canSubmit}
              >
                {mode === "add"
                  ? words.global.add
                  : words.managementTranslator.editBtn}
              </SubmitButton>
            </Guard>
          </div>
        </Col>
      </Row>

      <DeleteConfirmModal
        showConfirm={showDelete}
        onCancel={() => setShowDelete(false)}
        onOk={() => onDeleteDriver().finally(() => setShowDelete(false))}
        title={words.managementDriver.deleteTitle}
        okTitle={words.global.delete}
        loading={deleteLoading}
      />
    </>
  );
};

export default FormFooter;
