import { FC, useState } from "react";
import { Button, Col, Divider, Form, Row, Select, Spin } from "antd";
import FormItem from "antd/es/form/FormItem";
import SubmitButton from "src/components/UiKit/SubmitButton";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import DatePickerUi from "src/components/UiKit/DatePicker";
import { SelectUiKit } from "src/components/UiKit/select";
import { Gender } from "src/models/enums/gender";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";
import { useManagementTourBack } from "src/hook/useManagementTourBack";
import NationalCardImage from "./NationalCardImage";
import LeaderCardImage from "./LeaderCardImage";
import { useManagementLeaderData } from "../context";
import { Guard } from "src/components/Guard";
import {
  AddLeaderPath,
  DeleteLeaderPath,
  EditLeaderPath,
} from "src/services/TourManagement/guardPath";

const AddEditLeader: FC = () => {
  const [showDelete, setShowDelete] = useState<boolean>(false);

  const { words } = useLanguage();

  const { backHandler } = useManagementTourBack();

  const {
    states: { loading, mode, leaderForm, leaderloadings, deleteLoading },
    func: { onFinish },
    requests: { onDeleteLeader },
    dispatches: { setLeaderProps },
  } = useManagementLeaderData();

  const handleCancel = () => {
    if (mode === "add") {
      leaderForm.resetFields();
      setLeaderProps((prev) => ({
        ...prev,
        leaderCard: undefined,
        nationalCard: undefined,
      }));
    } else backHandler();
  };

  return (
    <>
      <p className="title">
        {mode === "add"
          ? words.managementLeader.addFormTitle
          : words.managementLeader.editFormTitle}
      </p>
      {leaderloadings.leader ||
      leaderloadings.leaderCard ||
      leaderloadings.nationalCard ? (
        <div className="flex w-full  justify-center items-center">
          <Spin size="large" />
        </div>
      ) : (
        <Form
          onFinish={onFinish}
          form={leaderForm}
          layout="vertical"
          preserve={false}
          className="mt-[40px]"
        >
          <Row gutter={[24, 12]}>
            <Col xs={24} md={8}>
              <FormItem
                name={"FirstName"}
                label={words.managementLeader.nameLabel}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <InputUiKit
                  placeholder={words.managementLeader.namePlaceHolder}
                />
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                name={"LastName"}
                label={words.managementLeader.familyNameLabel}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <InputUiKit
                  placeholder={words.managementTranslator.familyNamePlaceholder}
                />
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                name={"Gender"}
                label={words.managementLeader.genderLabel}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <SelectUiKit placeholder={words.managementLeader.select}>
                  <Select.Option value={Gender.Male}>
                    {words.global.male}
                  </Select.Option>
                  <Select.Option value={Gender.Female}>
                    {words.global.femail}
                  </Select.Option>
                </SelectUiKit>
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                name={"FatherName"}
                label={words.managementLeader.fatherNameLabel}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <InputUiKit
                  placeholder={words.managementLeader.fatherNamePlaceholder}
                />
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                name={"NationalCode"}
                label={words.managementLeader.nationalCodeLabel}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <InputUiKit
                  min={11}
                  max={11}
                  placeholder={words.managementLeader.nationalCodePlaceholder}
                  numbermode
                />
              </FormItem>
            </Col>
            <Col xs={24} md={8}>
              <FormItem
                name={"IdentityNumber"}
                label={words.managementLeader.identityLabel}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <InputUiKit
                  placeholder={words.managementLeader.identityPlaceholder}
                  numbermode
                />
              </FormItem>
            </Col>

            <Col xs={24} md={8}>
              <FormItem
                name={"BirthDate"}
                label={words.managementLeader.dateLabel}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <DatePickerUi
                  placeholder={words.managementLeader.datePlaceholder}
                />
              </FormItem>
            </Col>

            <Col xs={24} md={8}>
              <FormItem
                name={"Language"}
                label={words.managementLeader.languageLabel}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <InputUiKit
                  placeholder={words.managementLeader.languagePlaceholder}
                />
              </FormItem>
            </Col>

            <Col xs={24} md={8}>
              <FormItem
                name={"PhoneNumber"}
                label={words.managementLeader.phoneLabel}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <InputUiKit
                  placeholder={words.managementLeader.phonePlaceHolder}
                />
              </FormItem>
            </Col>

            <Col xs={24} md={8}>
              <FormItem
                name={"LeaderCardId"}
                label={words.managementLeader.leaderCardLabel}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <InputUiKit
                  placeholder={words.managementLeader.leaderCardPlaceholder}
                />
              </FormItem>
            </Col>

            <Col xs={24} md={8}>
              <FormItem
                name={"ExpireDate"}
                label={words.managementLeader.expireDateLabel}
                rules={[
                  { required: true, message: words.global.fieldRequired },
                ]}
              >
                <DatePickerUi
                  placeholder={words.managementLeader.datePlaceholder}
                />
              </FormItem>
            </Col>

            <Col span={24}>
              <Row gutter={[16, 16]} justify={"space-between"}>
                <NationalCardImage />
                <LeaderCardImage />
              </Row>
            </Col>

            <Divider className="bg-[#EEEEEE]" />
            <Col span={24} className="flex justify-between">
              <div>
                {mode === "edit" ? (
                  <Guard action={DeleteLeaderPath}>
                    <Button
                      type="default"
                      className="delete-btn"
                      onClick={() => setShowDelete(true)}
                    >
                      {words.global.delete}
                    </Button>
                  </Guard>
                ) : null}
              </div>
              <div className="flex justify-end">
                <Button
                  type="text"
                  className="bg-[#F5F5F5] text-[#8E8E8E] ml-2 rounded-lg w-[85px]"
                  onClick={handleCancel}
                >
                  {mode === "add"
                    ? words.managementLeader.clear
                    : words.managementLeader.cancel}
                </Button>
                <Guard action={EditLeaderPath || AddLeaderPath}>
                  <SubmitButton
                    form={leaderForm}
                    loading={loading}
                    canSubmit={false}
                  >
                    {mode === "add"
                      ? words.global.add
                      : words.managementLeader.editBtn}
                  </SubmitButton>
                </Guard>
              </div>
            </Col>
          </Row>
        </Form>
      )}

      <DeleteConfirmModal
        showConfirm={showDelete}
        onCancel={() => setShowDelete(false)}
        onOk={() => onDeleteLeader().finally(() => setShowDelete(false))}
        title={words.managementLeader.deleteTitle}
        okTitle={words.global.delete}
        loading={deleteLoading}
      />
    </>
  );
};

export default AddEditLeader;
