import { FC } from "react";
import { Col, Row } from "antd";
import { AddNewTouristInfo } from "./components/addNewTouristInfo";
import { TouristTable } from "./components/TouristTable";
import { AddNewTouristInfoContainer, AddTouristInfoContainer } from "./style";
import { useAddTouristInfo } from "./context";
import { TouristTableView } from "./components/touristTableView";
import { Guard } from "src/components/Guard";
import {
  AddTourTouristPath,
  GetAllTourTouristPath,
} from "src/services/TourManagement/guardPath";

export const AddTouristInfo: FC = () => {
  const {
    value: { mode },
  } = useAddTouristInfo();

  return (
    <>
      {(mode === "add" || mode === "edit") && (
        <Guard action={[GetAllTourTouristPath, AddTourTouristPath]}>
          <AddTouristInfoContainer>
            <Row justify={"center"}>
              <Col span={22}>
                <AddNewTouristInfoContainer>
                  <AddNewTouristInfo />
                  <TouristTable />
                </AddNewTouristInfoContainer>
              </Col>
            </Row>
          </AddTouristInfoContainer>
        </Guard>
      )}
      {mode === "view" && (
        <AddTouristInfoContainer>
          <Row justify={"center"}>
            <Col span={22}>
              <AddNewTouristInfoContainer>
                <TouristTableView />
              </AddNewTouristInfoContainer>
            </Col>
          </Row>
        </AddTouristInfoContainer>
      )}
    </>
  );
};
