import { Row } from "antd";
import styled from "styled-components";

export const CreateRoleContainer = styled(Row)`
  padding-top: 32px;
  .header {
    border-bottom: 1px solid var(--shadow, #dadeec);
    display: flex;
    padding: 0 24px 24px 24px;
    align-items: center;
    gap: 10px;
    color: var(--text-3, #5d6881);
    /* font-family: DM Sans; */
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
  }
  .content {
    border-radius: 20px;
    background: var(--0, #fff);

    /* 2 */
    box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
    padding: 24px 32px;
  }
  .title {
    color: var(--text, #001377);
    /* font-family: DM Sans; */
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .footer {
    border-top: 1px solid #dadeec;
    padding-top: 32px;
    padding-bottom: 16px;
  }
  .textarea {
    background: #f5f5f5 !important;
    border-color: #f5f5f5 !important;
    border-radius: 12px !important;
    &::placeholder {
      font-family: vazir;
      font-weight: 500;
      font-size: 12px;
      color: #8e8e8e;
    }
  }
  /* .ant-input {
    color: #000000;
  } */
`;

export const ModalContainer = styled.div`
  border: 1px solid #dadeec;
  padding: 48px;
  margin: 32px 0;
  border-radius: 8px;
  .ant-modal-content {
    padding: 24px 24px 0 24px;
  }
`;
