import "./styles/global.style.css";
import { Routers } from "./routes";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useEffect } from "react";
import Api from "./constants/allApiPath.json";

const App: React.FC = (): JSX.Element => {
  const queryClient = new QueryClient();
  useEffect(() => {
    const a = Api.filter((i) => i.path.includes("api/BaseInfo/AddCity"));
    console.log({ a });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-left" />
      <Routers />
    </QueryClientProvider>
  );
};
export default App;
