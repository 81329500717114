import { FC } from "react";
import { Col, Row } from "antd";
import { useDomesticFlight } from "./context";
import { DomesticFlightTable } from "./components/DomesticFlightTable";
import { AddDomesticFlightContainer, DomesticFlightContainer } from "./style";
import { AddDomesticFlight } from "./components/addDomesticFlight";
import { Guard } from "src/components/Guard";
import {
  AddDomesticFlightPath,
  GetAllDomesticFlightPath,
} from "src/services/TourManagement/guardPath";

export const DomesticFlight: FC = () => {
  const {
    value: { mode },
  } = useDomesticFlight();

  return (
    <>
      {(mode === "edit" || mode === "add") && (
        <Guard action={[AddDomesticFlightPath, GetAllDomesticFlightPath]}>
          <DomesticFlightContainer>
            <Row justify={"center"}>
              <Col span={22}>
                <AddDomesticFlightContainer>
                  <AddDomesticFlight />
                  <DomesticFlightTable />
                </AddDomesticFlightContainer>
              </Col>
            </Row>
          </DomesticFlightContainer>
        </Guard>
      )}
      {mode === "view" && (
        <DomesticFlightContainer>
          <Row justify={"center"}>
            <Col span={22}>
              <AddDomesticFlightContainer>
                <DomesticFlightTable />
              </AddDomesticFlightContainer>
            </Col>
          </Row>
        </DomesticFlightContainer>
      )}
    </>
  );
};
