import { FC } from "react";
import { Col } from "antd";
import { ManagementInsuranceContainer } from "./style";
import InsuranceAddEdit from "./components/insurance";

const ManagementInsurance: FC = () => {
  return (
    <>
      <InsuranceAddEdit />
    </>
  );
};

export default ManagementInsurance;
