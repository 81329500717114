import { MenuProps } from "antd";
import { Link } from "react-router-dom";
import useLanguage from "src/store/language";
import { getItem } from "../helper/createItem";
import { ROUTES } from "../../../models/enums/routes";
import useRoutesAccessStore from "src/store/routesAccess";

type MenuItem = Required<MenuProps>["items"][number];

export const useSidebarItems = () => {
  const { words } = useLanguage();

  const { routes } = useRoutesAccessStore();

  const items: MenuItem[] = [
    getItem(
      <Link to={ROUTES.home}>{words.sidebarItems.dashboard}</Link>,
      ROUTES.home,
      <span className="material-icons !text-[#a3aed0] !text-[24px]">
        dashboard
      </span>
    ),
    routes.addTour
      ? getItem(
          <Link to={ROUTES.addTour}>{words.sidebarItems.addTour}</Link>,
          ROUTES.addTour,
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            multiple_stop
          </span>
        )
      : null,
    getItem(
      <Link to={ROUTES.tourTable}>{words.sidebarItems.tourTable}</Link>,
      ROUTES.tourTable,
      <span className="material-icons !text-[#a3aed0] !text-[24px]">
        table_chart
      </span>
    ),
    routes.country ||
    routes.city ||
    routes.places ||
    routes.hotel ||
    routes.rooms ||
    routes.broker
      ? getItem(
          `${words.sidebarItems.basicInfo}`,
          ROUTES.basicInfo,
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            display_settings
          </span>,
          [
            routes.country
              ? getItem(
                  <Link to={ROUTES.country}>{words.sidebarItems.country}</Link>,
                  ROUTES.country
                )
              : null,
            routes.city
              ? getItem(
                  <Link to={ROUTES.city}>{words.sidebarItems.city}</Link>,
                  ROUTES.city
                )
              : null,
            routes.places
              ? getItem(
                  <Link to={ROUTES.places}>{words.sidebarItems.places}</Link>,
                  ROUTES.places
                )
              : null,
            routes.hotel
              ? getItem(
                  <Link to={ROUTES.hotel}>{words.sidebarItems.hotel}</Link>,
                  ROUTES.hotel
                )
              : null,
            routes.rooms
              ? getItem(
                  <Link to={ROUTES.rooms}>{words.sidebarItems.rooms}</Link>,
                  ROUTES.rooms
                )
              : null,
            routes.broker
              ? getItem(
                  <Link to={ROUTES.broker}>{words.sidebarItems.brokers}</Link>,
                  ROUTES.broker
                )
              : null,
          ]
        )
      : null,
    routes.CreateUser ||
    routes.CreateRole ||
    routes.AssignActionToRole ||
    routes.AssignRoleToUser
      ? getItem(
          `${words.sidebarItems.systemSettings}`,
          ROUTES.settings,
          <span className="material-icons !text-[#a3aed0] !text-[24px]">
            settings
          </span>,
          [
            routes.CreateRole
              ? getItem(
                  <Link to={ROUTES.CreateRole}>
                    {words.sidebarItems.createRole}
                  </Link>,
                  ROUTES.CreateRole
                )
              : null,
            routes.AssignActionToRole
              ? getItem(
                  <Link to={ROUTES.AssignActionToRole}>
                    {words.sidebarItems.assignActionToRole}
                  </Link>,
                  ROUTES.AssignActionToRole
                )
              : null,
            routes.CreateUser
              ? getItem(
                  <Link to={ROUTES.CreateUser}>
                    {words.sidebarItems.createUser}
                  </Link>,
                  ROUTES.CreateUser
                )
              : null,
            routes.AssignRoleToUser
              ? getItem(
                  <Link to={ROUTES.AssignRoleToUser}>
                    {words.sidebarItems.assignRoleToUser}
                  </Link>,
                  ROUTES.AssignRoleToUser
                )
              : null,
          ]
        )
      : null,
    // getItem(
    // <Link to={ROUTES.tourtable}> {words.sidebarItems.tourTable}</Link>,
    //   ROUTES.tourtable,
    //   <span className="material-icons !text-[#a3aed0] !text-[24px]">
    //     table_chart
    //   </span>
    // ),
  ];
  return items;
};
