import useLanguage from "src/store/language";
import { IManagementCard } from "../models/interface";
import { ROUTES } from "src/models/enums/routes";
import {
  AddCompanionLeaderPath,
  AddDomesticFlightPath,
  AddDriverPath,
  AddInsurancePath,
  AddLeaderPath,
  AddRoutePath,
  AddSimCardPath,
  AddSimCardTourTouristPath,
  AddTourAttachmentPath,
  AddTourPath,
  AddTourTimePath,
  AddTourTouristPath,
  AddTranslatorPath,
  EditCompanionLeaderPath,
  EditDomesticFlightPath,
  EditDriverPath,
  EditInsurancePath,
  EditLeaderPath,
  EditRoutePath,
  EditSimCardPath,
  EditSimCardTourTouristPath,
  EditTourAttachmentPath,
  EditTourPath,
  EditTourTimePath,
  EditTourTouristPath,
  EditTranslatorPath,
} from "src/services/TourManagement/guardPath";

export const useManagement = () => {
  const { words } = useLanguage();
  const cards: IManagementCard = {
    tourInfo: [
      {
        id: 1,
        icon: "basicInfo",
        name: `${words.managementTour.basicInfo}`,
        flag: "BasicInfo",
        endPoint: ROUTES.tourManagementBasicInfo,
        addDisable: AddTourPath,
        editDisable: EditTourPath,
      },
      {
        id: 2,
        icon: "hiking",
        name: `${words.managementTour.tourists}`,
        flag: "Tourists",
        endPoint: ROUTES.tourManagementTouristInfo,
        addDisable: AddTourTouristPath,
        editDisable: EditTourTouristPath,
      },
      {
        id: 3,
        icon: "timeFrame",
        name: `${words.managementTour.timeFrame}`,
        flag: "TourTime",
        endPoint: ROUTES.tourManagementTime,
        addDisable: AddTourTimePath,
        editDisable: EditTourTimePath,
      },
      {
        id: 4,
        icon: "tourRoutes",
        name: `${words.managementTour.tourRoutes}`,
        flag: "Routes",
        endPoint: ROUTES.tourManagementRoute,
        addDisable: AddRoutePath,
        editDisable: EditRoutePath,
      },
      {
        id: 5,
        icon: "sdCardAlert",
        name: `${words.managementTour.buySimcard}`,
        flag: "BuySimCard",
        endPoint: ROUTES.tourManagementSimcard,
        addDisable: AddSimCardPath,
        editDisable: EditSimCardPath,
      },
      {
        id: 6,
        icon: "SIMCard",
        name: `${words.managementTour.SIMCard}`,
        flag: "AssignSimCard",
        endPoint: ROUTES.tourManagementAssignSimcard,
        addDisable: AddSimCardTourTouristPath,
        editDisable: EditSimCardTourTouristPath,
      },
      {
        id: 7,
        icon: "insurance",
        name: `${words.managementTour.insurance}`,
        flag: "Insurance",
        endPoint: ROUTES.tourManagementInsurance,
        addDisable: AddInsurancePath,
        editDisable: EditInsurancePath,
      },

      {
        id: 8,
        icon: "domesticFlight",
        name: `${words.managementTour.domesticFlight}`,
        flag: "DomesticFlight",
        endPoint: ROUTES.domesticFlight,
        addDisable: AddDomesticFlightPath,
        editDisable: EditDomesticFlightPath,
      },
      {
        id: 9,
        icon: "documentation",
        name: `${words.managementTour.documentation}`,
        flag: "Attachments",
        endPoint: ROUTES.tourManagementFiles,
        addDisable: AddTourAttachmentPath,
        editDisable: EditTourAttachmentPath,
      },
    ],
    teemInfo: [
      {
        id: 1,
        icon: "translator",
        name: `${words.managementTour.translator}`,
        flag: "Translator",
        endPoint: ROUTES.tourManagementTranslator,
        addDisable: AddTranslatorPath,
        editDisable: EditTranslatorPath,
      },
      {
        id: 2,
        icon: "leader",
        name: `${words.managementTour.leader}`,
        flag: "Leader",
        endPoint: ROUTES.tourManagementLeader,
        addDisable: AddLeaderPath,
        editDisable: EditLeaderPath,
      },
      {
        id: 3,
        icon: "driver",
        name: `${words.managementTour.driver}`,
        flag: "Driver",
        endPoint: ROUTES.tourManagementDriver,
        addDisable: AddDriverPath,
        editDisable: EditDriverPath,
      },
      {
        id: 4,
        icon: "broker",
        name: `${words.managementTour.companionLeader}`,
        flag: "CompanionLeader",
        endPoint: ROUTES.tourManagementCompanionLeader,
        addDisable: AddCompanionLeaderPath,
        editDisable: EditCompanionLeaderPath,
      },
    ],
    // notificationInfo: [
    //   {
    //     id: 4,
    //     icon: "notifications",
    //     name: `${words.managementTour.notifications}`,
    //     flag: "Notification",
    //     endPoint: ROUTES.notification,
    //   },
    // ],
  };
  return cards;
};
