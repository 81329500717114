import { FC, useEffect, useState } from "react";
import { CreateRoleContainer, ModalContainer } from "./styles";
import { Button, Col, Flex, Form, Input, Modal, Row, TreeSelect } from "antd";
import { FormItem } from "src/components/UiKit/FormItem";
import useLanguage from "src/store/language";
import InputUiKit from "src/components/UiKit/Input";
import { useCreateRoleContext } from "./context";
import { Guard } from "src/components/Guard";
import { RolesPath } from "src/services/UserManagement/guardPath";

export const CreateRole: FC = () => {
  const {
    onFinish,
    onCancelConfirm,
    onConfirm,
    showConfirm,
    treeProps,
    confirmLoading,
    createRoleForm,
  } = useCreateRoleContext();

  const { words } = useLanguage();

  const reset = () => {
    createRoleForm?.resetFields();
  };
  const [submittable, setSubmittable] = useState<boolean>(false);

  // Watch all values
  const values = Form.useWatch([], createRoleForm);

  useEffect(() => {
    createRoleForm
      ?.validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [createRoleForm, values]);
  return (
    <Guard action={RolesPath}>
      <Form onFinish={onFinish} form={createRoleForm}>
        <CreateRoleContainer justify={"center"} gutter={[0, 24]}>
          <Col span={15} className="content">
            <Row gutter={[24, 12]}>
              <Col span={24} className="mb-[40px]">
                <div className="title">
                  {words.systemSettings.createRoleFormTitle}
                </div>
              </Col>
              <Col xs={24} lg={12}>
                <FormItem
                  name={"name"}
                  rules={[
                    { required: true, message: words.global.fieldRequired },
                  ]}
                  label={words.systemSettings.rolName}
                >
                  <InputUiKit
                    placeholder={words.systemSettings.roleSelectPlaceHolder}
                  />
                </FormItem>
              </Col>
              <Col xs={24} lg={12}>
                <FormItem
                  name={"parentId"}
                  label={words.systemSettings.seniorRole}
                >
                  <TreeSelect
                    {...treeProps}
                    multiple={false}
                    allowClear
                    className="selectOption"
                    placeholder={words.systemSettings.seniorRolePlaceHolder}
                  />
                </FormItem>
              </Col>
              <Col span={24}>
                <FormItem
                  required
                  name={"comment"}
                  label={words.systemSettings.description}
                  rules={[
                    { required: true, message: words.global.fieldRequired },
                  ]}
                >
                  <Input.TextArea
                    autoSize={{ minRows: 5, maxRows: 5 }}
                    className="py-[16px] px-[12px] textarea"
                    placeholder={words.systemSettings.descriptionPlaceHolder}
                  />
                </FormItem>
              </Col>
              <Col span={24} className="flex gap-[24px] footer">
                <Flex justify="end" align="center" gap={8} className="w-full">
                  <Button
                    type="text"
                    className="bg-[#F5F5F5] text-[#8E8E8E] ml-2 w-[85px]"
                    onClick={reset}
                  >
                    {words.basicInfo.buttonClear}
                  </Button>
                  <Button
                    type="primary"
                    className="bg-[#F06614] text-[#ffffff] w-[85px]"
                    htmlType="submit"
                    disabled={!submittable}
                  >
                    {words.basicInfo.buttonAdd}
                  </Button>
                </Flex>
              </Col>
            </Row>
          </Col>
        </CreateRoleContainer>
      </Form>

      <Modal
        open={showConfirm}
        onCancel={onCancelConfirm}
        closeIcon={false}
        footer={false}
        title={words.systemSettings.createRoleConfirmTitle}
      >
        <ModalContainer>
          <p className="m-0 text-[16px] text-[#001377] font-medium">
            {words.systemSettings.createRoleConfirmMessage}
          </p>
        </ModalContainer>
        <div className="border-0 border-t-[1px] border-solid border-[#dadeec] pt-[16px]">
          <Flex justify="end" gap={12} align="center" className="w-full">
            <Button
              type="text"
              className="bg-[#F5F5F5] text-[#4B4B4B] w-[85px]"
              onClick={onCancelConfirm}
            >
              لغو
            </Button>
            <Button
              type="text"
              className="bg-[#F06614] text-[#FFF] w-[85px]"
              onClick={onConfirm}
              loading={confirmLoading}
            >
              تایید
            </Button>
          </Flex>
        </div>
      </Modal>
    </Guard>
  );
};
