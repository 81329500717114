import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "src/models/enums/routes";
import useGlobalStore from "src/store/global";
import { useTourTableData } from "../context/TourTableData";
import useLanguage from "src/store/language";
import { Guard } from "src/components/Guard";
import {
  DeleteTourPath,
  GetTourManagementPath,
} from "src/services/TourManagement/guardPath";

const TableActions: FC<{ id: number; state: number; code: string }> = ({
  id,
  state,
  code,
}) => {
  const { words } = useLanguage();

  const navigate = useNavigate();

  const { setTourCode } = useGlobalStore();

  const {
    dispatches: { setDeleteId, setDisableConfirm },
  } = useTourTableData();

  const handleTourManagement = () => {
    setTourCode(code);
    navigate(ROUTES.tourTable.concat("/" + id + ROUTES.managementTour));
  };

  return (
    <>
      <div className="flex justify-center gap-[16px]">
        <Guard action={GetTourManagementPath}>
          <div
            className="management-tour-action"
            onClick={handleTourManagement}
          >
            <span
              className="material-icons text-[14px] !text-[#3A70E2] cursor-pointer"
              title={words.tableTour.tourManagement}
            >
              display_settings
            </span>
            <span>{words.global.managementTour}</span>
          </div>
        </Guard>

        <Guard action={DeleteTourPath}>
          <div
            className="delete-action"
            onClick={() => {
              setDeleteId(id);
              setDisableConfirm(true);
            }}
          >
            <span className="material-icons text-[14px]">delete_forever</span>

            <span>{words.global.delete}</span>
          </div>
        </Guard>
      </div>
    </>
  );
};

export default TableActions;
