import { FC, useEffect, useState } from "react";
import { Button, Col, Divider, Form, Row, Select, Spin, message } from "antd";
import FormItem from "antd/es/form/FormItem";
import dayjs from "dayjs";
import SubmitButton from "src/components/UiKit/SubmitButton";
import useLanguage from "src/store/language";
import { useNavigate } from "react-router-dom";
import AdDatePickerUi from "src/components/UiKit/DatePickerMiladi";
import { convertDate, convertDatePicker } from "src/helper";
import DeleteConfirmModal from "src/components/UiKit/DeleteConfirmModal.tsx";
import { SelectUiKit } from "src/components/UiKit/select";
import { TourStates } from "src/models/enums/TourStates";
import { useManagementTimeData } from "../context";
import { useFormHasChanged } from "../useFormHasChanged";
import { Guard } from "src/components/Guard";
import { DeleteTourTimePath } from "src/services/TourManagement/guardPath";

const AddEditTime: FC = () => {
  const [startDateAd, setStartDateAd] = useState<Date | undefined | null>(
    undefined
  );
  const [endDateAd, setEndDateAd] = useState<Date | undefined | null>(
    undefined
  );
  const [flightDate, setFlightDate] = useState<Date | undefined | null>(
    undefined
  );
  const [cancelDate, setCancelDate] = useState<Date | undefined | null>(
    undefined
  );
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [durationDays, setDurationDays] = useState<number | undefined>(
    undefined
  );

  const { words } = useLanguage();

  const [timeTourForm] = Form.useForm();

  const navigate = useNavigate();

  const {
    states: { loading, tourTime, mode, deleteLoading, dataLoading },
    func: { onFinish },
    requests: { onDeleteTime },
  } = useManagementTimeData();

  const canSubmit = useFormHasChanged(timeTourForm);

  const formValues = Form.useWatch([], timeTourForm);

  const handleCancel = () => {
    if (mode === "add") {
      timeTourForm.resetFields();
      setStartDateAd(undefined);
      setEndDateAd(undefined);
      setFlightDate(undefined);
      setCancelDate(undefined);
    } else navigate(-1);
  };

  useEffect(() => {
    if (tourTime) {
      timeTourForm.setFieldsValue({
        start: tourTime.startDate ? dayjs(tourTime.startDate) : null,
        startDate: tourTime.startDate ? new Date(tourTime.startDate) : null,
        end: tourTime.endDate ? dayjs(tourTime.endDate) : null,
        endDate: tourTime.endDate ? new Date(tourTime.endDate) : null,
        cancel: tourTime.cancelDate ? dayjs(tourTime.cancelDate) : null,
        cancelDate: tourTime.cancelDate ? new Date(tourTime.cancelDate) : null,
        flightDate: tourTime.flightDate ? new Date(tourTime.flightDate) : null,
        duration: tourTime.tourDays ? Number(tourTime.tourDays) : null,
        tourState: tourTime.tourState,
      });
      setStartDateAd(tourTime.startDate ? new Date(tourTime.startDate) : null);
      setEndDateAd(tourTime.endDate ? new Date(tourTime.endDate) : null);
      setFlightDate(tourTime.flightDate ? new Date(tourTime.flightDate) : null);
      setCancelDate(tourTime.cancelDate ? new Date(tourTime.cancelDate) : null);
    } else {
      timeTourForm.resetFields();
      setStartDateAd(null);
      setEndDateAd(null);
      setFlightDate(null);
      setCancelDate(null);
    }
  }, [timeTourForm, tourTime]);

  useEffect(() => {
    if (startDateAd && endDateAd) {
      var start = new Date(startDateAd);
      var end = new Date(endDateAd);
      const diffTime: number = end.getTime() - start.getTime();
      if (diffTime > 0) {
        const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
        setDurationDays(diffDays);
      } else {
        setEndDateAd(null);
        timeTourForm.setFieldValue("endDate", null);
        message.error(words.managementTime.dateError);
      }
    } else setDurationDays(undefined);
  }, [startDateAd, endDateAd, timeTourForm, words.managementTime.dateError]);

  return (
    <>
      {dataLoading ? (
        <div className="w-full flex justify-center items-center">
          <Spin size="large" />
        </div>
      ) : (
        <>
          <p className="title ">
            {mode === "add"
              ? words.managementTime.addFormTitle
              : words.managementTime.editFormTitle}
          </p>
          <Form
            onFinish={onFinish}
            form={timeTourForm}
            layout="vertical"
            preserve={false}
            className="mt-[40px]"
          >
            <Row gutter={[24, 12]}>
              <Col xs={24} md={8}>
                <FormItem
                  name={"startDate"}
                  label={words.managementTime.startTourMiladiLabel}
                  rules={[
                    { required: true, message: words.global.fieldRequired },
                  ]}
                >
                  <AdDatePickerUi
                    placeholderText={words.managementTime.select}
                    selected={startDateAd}
                    onChange={(date) => {
                      setStartDateAd(date);
                      timeTourForm.setFieldValue(
                        "start",
                        date ? convertDatePicker(date) : null
                      );
                    }}
                  />
                </FormItem>
              </Col>

              <Col xs={24} md={8}>
                <FormItem
                  name={"start"}
                  label={words.managementTime.startTourLabel}
                >
                  <div className="calculated-date">
                    {startDateAd ? (
                      <span>{convertDate(startDateAd)}</span>
                    ) : (
                      words.managementTime.calculated
                    )}
                  </div>
                </FormItem>
              </Col>

              <Col xs={24} md={8}>
                <FormItem
                  name={"flightDate"}
                  label={words.managementTime.flightLabel}
                >
                  <AdDatePickerUi
                    placeholderText={words.managementTime.select}
                    selected={flightDate}
                    onChange={(date) => setFlightDate(date)}
                  />
                </FormItem>
              </Col>

              <Col xs={24} md={8}>
                <FormItem
                  name={"endDate"}
                  label={words.managementTime.endTourMiladiLabel}
                  rules={[
                    {
                      required:
                        formValues &&
                        (formValues.tourState === TourStates.Progress ||
                          formValues.tourState === TourStates.Finished)
                          ? true
                          : false,
                      message: words.global.fieldRequired,
                    },
                  ]}
                >
                  <AdDatePickerUi
                    placeholderText={words.managementTime.select}
                    selected={endDateAd}
                    onChange={(date) => {
                      setEndDateAd(date);
                      timeTourForm.setFieldValue(
                        "end",
                        date ? convertDatePicker(date) : null
                      );
                    }}
                  />
                </FormItem>
              </Col>
              <Col xs={24} md={8}>
                <FormItem
                  name={"end"}
                  label={words.managementTime.endTourLabel}
                >
                  <div className="calculated-date">
                    {endDateAd ? (
                      <span>{convertDate(endDateAd)}</span>
                    ) : (
                      words.managementTime.calculated
                    )}
                  </div>
                </FormItem>
              </Col>
              <Col xs={24} md={8}>
                <FormItem
                  name={"duration"}
                  label={words.managementTime.tourDurationLabel}
                >
                  <div className="calculated-date">
                    {durationDays ? (
                      <span>{durationDays}</span>
                    ) : (
                      words.managementTime.calculated
                    )}
                  </div>
                </FormItem>
              </Col>

              <Col xs={24} md={8}>
                <FormItem
                  name={"cancelDate"}
                  label={words.managementTime.cancelMiladiLabel}
                  rules={[
                    {
                      required:
                        formValues &&
                        formValues.tourState === TourStates.Inactive
                          ? true
                          : false,
                      message: words.global.fieldRequired,
                    },
                  ]}
                >
                  <AdDatePickerUi
                    placeholderText={words.managementTime.select}
                    selected={cancelDate}
                    onChange={(date) => {
                      setCancelDate(date);
                      timeTourForm.setFieldValue(
                        "cancel",
                        date ? convertDatePicker(date) : null
                      );
                    }}
                  />
                </FormItem>
              </Col>
              <Col xs={24} md={8}>
                <FormItem
                  name={"cancel"}
                  label={words.managementTime.cancelLabel}
                >
                  <div className="calculated-date">
                    {cancelDate ? (
                      <span>{convertDate(cancelDate)}</span>
                    ) : (
                      words.managementTime.calculated
                    )}
                  </div>
                </FormItem>
              </Col>

              <Col xs={24} md={8}>
                <FormItem
                  name={"tourState"}
                  label={words.managementTime.tourState}
                  rules={[
                    { required: true, message: words.global.fieldRequired },
                  ]}
                >
                  <SelectUiKit placeholder={words.managementTime.select}>
                    <Select.Option value={TourStates.Waiting}>
                      {words.tableTour.waitingToStart}
                    </Select.Option>
                    <Select.Option value={TourStates.Progress}>
                      {words.tableTour.progress}
                    </Select.Option>
                    <Select.Option value={TourStates.Finished}>
                      {words.tableTour.finished}
                    </Select.Option>
                    <Select.Option value={TourStates.Inactive}>
                      {words.tableTour.inactive}
                    </Select.Option>
                  </SelectUiKit>
                </FormItem>
              </Col>

              <Divider className="bg-[#EEEEEE]" />
              <Col
                span={24}
                className={`flex ${
                  DeleteTourTimePath ? "justify-between" : "justify-end"
                }`}
              >
                <Guard action={DeleteTourTimePath}>
                  {mode === "edit" ? (
                    <Button
                      type="default"
                      className="delete-btn"
                      onClick={() => setShowDelete(true)}
                    >
                      {words.global.delete}
                    </Button>
                  ) : null}
                </Guard>
                <div className="flex justify-end">
                  <Button
                    type="text"
                    className="bg-[#F5F5F5] text-[#8E8E8E] ml-2 w-[85px]"
                    onClick={handleCancel}
                  >
                    {mode === "add"
                      ? words.managementTime.clear
                      : words.managementTime.cancel}
                  </Button>
                  <SubmitButton
                    form={timeTourForm}
                    loading={loading}
                    canSubmit={canSubmit}
                  >
                    {mode === "add"
                      ? words.global.add
                      : words.managementTime.editBtn}
                  </SubmitButton>
                </div>
              </Col>
            </Row>
          </Form>

          <DeleteConfirmModal
            showConfirm={showDelete}
            onCancel={() => setShowDelete(false)}
            onOk={() => onDeleteTime().finally(() => setShowDelete(false))}
            title={words.managementTime.deleteTitle}
            okTitle={words.global.delete}
            loading={deleteLoading}
          />
        </>
      )}
    </>
  );
};

export default AddEditTime;
