import { FC } from "react";
import { Col } from "antd";
import InsuranceInfo from "./form/InsuranceInfo";
import { InsuranceAddEditTabsContainer } from "./style";
import AddEditInsuranceDataProvider from "./context";
import InsuranceTable from "./table/InsuranceTable";
import { useManagementInsuranceData } from "../../context";
import { ManagementInsuranceContainer } from "../../style";
import { Guard } from "src/components/Guard";
import {
  AddInsuranceAttachmentPath,
  AddInsurancePath,
  GetAllInsurancePath,
} from "src/services/TourManagement/guardPath";

const InsuranceAddEdit: FC = () => {
  const {
    states: { mode },
  } = useManagementInsuranceData();

  return (
    <AddEditInsuranceDataProvider>
      <>
        {(mode === "add" || mode === "edit") && (
          <Guard action={[AddInsurancePath, GetAllInsurancePath]}>
            <ManagementInsuranceContainer justify={"center"} gutter={[0, 24]}>
              <Col xs={24} md={23} className="content">
                <InsuranceAddEditTabsContainer>
                  <Col span={24}>
                    <InsuranceInfo />
                  </Col>
                </InsuranceAddEditTabsContainer>
                <InsuranceTable />
              </Col>
            </ManagementInsuranceContainer>
          </Guard>
        )}
        {mode === "view" && (
          <ManagementInsuranceContainer justify={"center"} gutter={[0, 24]}>
            <Col xs={24} md={23} className="content">
              <InsuranceTable />
            </Col>
          </ManagementInsuranceContainer>
        )}
      </>
    </AddEditInsuranceDataProvider>
  );
};

export default InsuranceAddEdit;
