import { Flex } from "antd";
import { FC } from "react";
import { IBroker } from "src/services/BasicInformation/models";
import useLanguage from "src/store/language";
import { useBrokersData } from "../../context/BrokersData";
import { Guard } from "src/components/Guard";
import {
  DeleteBrokerPath,
  UpdateBrokerPath,
} from "src/services/BasicInformation/baseGuardPath";

interface ITableActionsProps {
  item: IBroker;
}

const TableActions: FC<ITableActionsProps> = ({ item }) => {
  const { words } = useLanguage();

  const {
    func: { handleDeleteId },
    dispatches: { setSelectedBroker },
  } = useBrokersData();

  const handleSelectPlace = () => {
    setSelectedBroker(item);
  };

  return (
    <>
      <Flex justify="center" className="gap-[16px]">
        <Guard action={UpdateBrokerPath}>
          <div className="edit-action" onClick={() => handleSelectPlace()}>
            <span className="material-icons text-[#0C9D61]  text-[16px]">
              edit_note
            </span>
            <span>{words.global.editAction}</span>
          </div>
        </Guard>
        <Guard action={DeleteBrokerPath}>
          <div
            className="delete-action"
            onClick={() => handleDeleteId(item.id)}
          >
            <span className="material-icons text-[#ec2d30] text-[14px]">
              delete_forever
            </span>
            <span>{words.global.delete}</span>
          </div>
        </Guard>
      </Flex>
    </>
  );
};

export default TableActions;
