import { ConfigProvider } from "antd";
import { FC, ReactNode } from "react";
import { OverrideToken } from "antd/es/theme/interface";
import useLanguage from "src/store/language";
import { GlobalStyle } from "../styles/global.style";
interface IProps {
  children: ReactNode;
}

const ConfigProviders: FC<IProps> = ({ children }) => {
  const { isRtl } = useLanguage();
  const darkThemeComponents: OverrideToken = {
    Button: {
      borderRadius: 8,
      colorBgBase: "#F5F5F5",
      fontSize: 14,
      controlHeight: 42,
      paddingContentHorizontal: 24,
      colorBorder: "#F5F5F5",
      colorBgContainer: "#F5F5F5",
      colorText: "#8E8E8E",
      colorBgContainerDisabled: "#FAD1B8",
      colorTextDisabled: "#FDE8DC",

      paddingInline: 8,
      primaryColor: "#ffffff",
      colorPrimary: "#F06614",
      colorPrimaryBgHover: "#F06614",
      colorPrimaryHover: "#F06614",
    },
    Input: {
      colorTextPlaceholder: "#8e8e8e",
      // colorBgContainerDisabled: "#E9EAF1",
      // colorTextDisabled: "#1F1F1F",
      colorBgContainer: "#F5F5F5",
    },
    // Dropdown: {
    //   colorPrimaryBg: "rgba(31, 34, 41, 0.9)",
    //   colorBgBase: "rgba(31, 34, 41, 0.9)",
    //   colorBgElevated: "rgba(31, 34, 41, 0.9)",
    //   colorText: "#949494",
    // },
    // Select: {
    //   colorBgContainer: "transparent",
    //   colorBorder: "#56575B",
    //   colorBgElevated: "#2C303A",
    //   colorTextPlaceholder: "#949494",
    //   controlHeight: 45,
    //   borderRadius: 8,
    //   controlItemBgActive: "#353A46",
    // },
    // Drawer: {
    //   colorBgLayout: "#282B34",
    //   colorBgContainer: "#282B34",
    //   colorBgElevated: "#282B34",
    //   colorText: "#D3D3D3",
    //   colorTextBase: "#D3D3D3",
    // },
    // Menu: {
    //   colorItemBgActive: "#26A69A",
    //   colorText: "#D3D3D3",
    //   colorItemBgSelected: "#24E3BF1A",
    //   radiusItem: 2,
    //   colorItemTextSelected: "#25E3BF",
    //   colorItemText: "#D3D3D3",
    //   colorSubItemBg: "transparent",
    // },
    Modal: {
      colorBgMask: "rgba(44, 115, 252, 0.40)",
      titleColor: "#001377",
      titleFontSize: 16,
    },
    // Table: {
    //   colorBgContainer: "transparent",
    //   colorText: "#949494",
    //   colorTextHeading: "#D3D3D3",
    // },
    // Radio: {
    //   colorBgContainer: "#56575B",
    //   colorBorder: "transparent",
    // },
    // DatePicker: {
    //   colorBgLayout: "#282B34",
    //   colorBgContainer: "#282B34",
    //   colorBgElevated: "#282B34",
    // },
  };

  const antdTheme = {
    components: darkThemeComponents,
  };

  return (
    <ConfigProvider theme={antdTheme} direction={isRtl ? "rtl" : "ltr"}>
      <GlobalStyle />
      {children}
    </ConfigProvider>
  );
};

export default ConfigProviders;
