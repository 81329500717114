import { Button, Divider, Form, Input, Modal } from "antd";
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import { ChangePasswordContainer } from "./changePassword.style";
import { IdentityService } from "src/services/Identity/Identity.service";
import { IChangePassword } from "src/services/Identity/models";
import { useLogin, useMediaQuery } from "src/hook";
import { FormItem } from "src/components/UiKit/FormItem";
import { breakPointsSm } from "src/constants/screen";
import useLanguage from "src/store/language";

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
}
interface IChangePasswordForm extends IChangePassword {
  repeatPassword?: string;
}
export const ChangePassword: FC<IProps> = ({ isModalOpen, setIsModalOpen }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const { loginHandler } = useLogin();
  const isMobileDevice = !useMediaQuery(breakPointsSm);
  const { words } = useLanguage();

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const onFinish = async (values: IChangePasswordForm) => {
    try {
      setLoading(true);
      const { ChangePassword } = new IdentityService();
      if (values.newPassword === values.repeatPassword) {
        delete values.repeatPassword;
        const result = await ChangePassword(values);
        if (result && result.data && result.status === 200) {
          loginHandler(
            result.data.accessToken,
            result.data.roles,
            result.data.paths
          );
        }
        form.resetFields();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <Modal
        width={isMobileDevice ? "95%" : "34.7%"}
        title={
          <span className="text-[18px] text-[#233069] font-bold">
            {words.mainLayout.changePassword}
          </span>
        }
        open={isModalOpen}
        closable={false}
        onCancel={handleCancel}
        styles={{ body: { height: "500px" } }}
        footer={null}
        afterClose={() => {
          form.resetFields();
        }}
        className="relative"
        centered
      >
        <>
          <Divider className="bg-[#fde8dc] mt-[5px]" />
          <ChangePasswordContainer>
            <Form className="w-[100%]" onFinish={onFinish} form={form}>
              <div className="form-container">
                <div className="nameHolder mb-[20px]">
                  {words.changePassword.title}
                </div>
                <FormItem
                  name="oldPassword"
                  label={words.changePassword.oldPassword}
                  rules={[
                    { required: true, message: words.global.fieldRequired },
                  ]}
                >
                  <Input.Password
                    placeholder={words.changePassword.oldPassword}
                    className="inputPassword"
                    autoComplete="off"
                  />
                </FormItem>
                <FormItem
                  name="newPassword"
                  label={words.changePassword.newPassword}
                  rules={[
                    { required: true, message: words.global.fieldRequired },
                  ]}
                >
                  <Input.Password
                    placeholder={words.changePassword.newPassword}
                    className="inputPassword"
                    autoComplete="off"
                  />
                </FormItem>
                <FormItem
                  name="repeatPassword"
                  label={words.changePassword.repeatNewPassword}
                  style={{}}
                  dependencies={["newPassword"]}
                  rules={[
                    { required: true, message: words.global.fieldRequired },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("newPassword") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(words.changePassword.notMatch)
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder={words.changePassword.repeatNewPassword}
                    className="inputPassword"
                    autoComplete="off"
                  />
                </FormItem>
              </div>
              <div className="flex justify-center gap-[24px] footer">
                <Button
                  className="w-[30%] save"
                  type="text"
                  htmlType="button"
                  loading={loading}
                  onClick={form.submit}
                  disabled={!submittable || loading}
                >
                  {words.global.save}
                </Button>
                <Button
                  className="w-[30%]"
                  htmlType="button"
                  onClick={() => {
                    handleCancel();
                    form.resetFields();
                  }}
                >
                  {words.global.close}
                </Button>
              </div>
            </Form>
          </ChangePasswordContainer>
        </>
      </Modal>
    </div>
  );
};
