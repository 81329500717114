import { FC, useState } from "react";
import { useAssignRoleToUser } from "./context";
import { AssignRoleToUserContainer } from "./styles";
import { Button, Col, Flex, Input } from "antd";
import { TableUI } from "src/components/UiKit/table";
import { getRowKey } from "src/helper/getRowKey";
import { useSearchParams } from "react-router-dom";
import useLanguage from "src/store/language";
import { AssignRoleToUserModal } from "./components/AssignRoleToUserModal";
import "./styles/popconfirm.css";
import { ResetPassword } from "../ResetPassword";
import { Guard } from "src/components/Guard";
import { userReportPath } from "src/services/UserManagement/guardPath";

export const AssignRoleToUser: FC = () => {
  const {
    values: {
      tableColumns,
      dataSource,
      showAssign,
      treeData,
      currentCheckedKeys,
      loadingRolesOfAction,
      assignLoading,
      allUserQuery,
      showPassword,
      currentUser,
      pagination,
    },
    func: {
      onCancelAssign,
      onAssign,
      onCancelPassword,
      onChangeState,
      goToFirstPage,
    },
    dispatch: { setPagination },
  } = useAssignRoleToUser();
  const [userStateFilter, setUserStateFilter] = useState<
    "undefined" | "true" | "false"
  >("undefined");

  let [searchParams] = useSearchParams();
  const { words } = useLanguage();

  const handleFilterState = (value: "undefined" | "true" | "false") => {
    setUserStateFilter(value);
    value === "undefined"
      ? onChangeState("Enable")
      : onChangeState("Enable", value);
    goToFirstPage();
  };

  return (
    <Guard action={userReportPath}>
      <AssignRoleToUserContainer justify={"center"} gutter={[0, 24]}>
        <Col span={24} className="header">
          <Flex align="center" justify="space-between">
            <Input
              placeholder={
                words.systemSettings.assignRoleToUserSearchPlaceholder
              }
              prefix={
                <span className="material-icons text-[#1B2559]">search</span>
              }
              className="w-[341px] h-[46px] rounded-[10px]"
              onPressEnter={(event) => {
                const value = event.currentTarget.value;
                onChangeState("UserName", value.trim());
                goToFirstPage();
              }}
              autoComplete="off"
              onChange={(event) => {
                //setSearchValue(event.target.value);
                if (!event.target.value.trim()) {
                  onChangeState("UserName");
                  goToFirstPage();
                }
              }}
              defaultValue={searchParams.get("UserName") || ""}
            />

            <Flex align="center">
              <Button
                type="text"
                className={`${userStateFilter === "undefined" ? "active" : ""}`}
                onClick={() => handleFilterState("undefined")}
              >
                {userStateFilter === "undefined" && (
                  <img src="/assets/images/check-circle.svg" alt="done" />
                )}
                <span className="btn-title">
                  {words.systemSettings.allUserFilterStateTitle}
                </span>
              </Button>
              <Button
                type="text"
                className={`${userStateFilter === "true" ? "active" : ""}`}
                onClick={() => handleFilterState("true")}
              >
                {userStateFilter === "true" && (
                  <img src="/assets/images/check-circle.svg" alt="done" />
                )}
                <span className="btn-title">
                  {words.systemSettings.activeUserFilterStateTitle}
                </span>
              </Button>
              <Button
                type="text"
                className={`${userStateFilter === "false" ? "active" : ""}`}
                onClick={() => handleFilterState("false")}
              >
                {userStateFilter === "false" && (
                  <img src="/assets/images/check-circle.svg" alt="done" />
                )}
                <span className="btn-title">
                  {words.systemSettings.inActiveUserFilterStateTitle}
                </span>
              </Button>
            </Flex>
          </Flex>
        </Col>
        <Col span={20} order={2} className="content">
          <TableUI
            columns={tableColumns}
            dataSource={dataSource}
            loading={allUserQuery?.isFetching}
            rowKey={getRowKey}
            scroll={{ x: 700 }}
            tableType="secondary"
            pagination={{
              position: ["bottomCenter"],
              total: pagination.total,
              current: pagination.current,
              onChange: (page: number, pageSize: number) => {
                onChangeState("Limit", pageSize.toString());
                onChangeState(
                  "Offset",
                  page !== pagination.current ? "1" : page.toString()
                );
                setPagination((prev) => ({ ...prev, current: page, pageSize }));
              },
            }}
          />
        </Col>
        <AssignRoleToUserModal
          open={showAssign}
          onCancel={onCancelAssign}
          title={words.systemSettings.assignRoleToUserTitle}
          treeData={treeData}
          onAssign={onAssign}
          currentCheckedKeys={currentCheckedKeys}
          loading={loadingRolesOfAction}
          confirmLoading={assignLoading}
        />
        <ResetPassword
          isModalOpen={showPassword}
          onCancel={onCancelPassword}
          currentUser={currentUser}
        />
      </AssignRoleToUserContainer>
    </Guard>
  );
};
