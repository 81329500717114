import { FC } from "react";
import { Col } from "antd";
import AddEditRouteHotels from "./components/form/AddEditRouteHotels";
import RouteHotelsTable from "./components/table/RouteHotelsTable";
import { useRouteHotelsData } from "./context";
import { ManagementRouteRoomContainer } from "./style";
import { Guard } from "src/components/Guard";
import {
  AddRouteRoomPath,
  GetAllRouteRoomPath,
} from "src/services/TourManagement/guardPath";

const RouteHotels: FC = () => {
  const {
    states: { mode },
  } = useRouteHotelsData();

  return (
    <>
      {(mode === "add" || mode === "edit") && (
        <Guard action={[AddRouteRoomPath, GetAllRouteRoomPath]}>
          <ManagementRouteRoomContainer justify={"center"} gutter={[0, 24]}>
            <Col xs={24} md={23} className="content">
              <AddEditRouteHotels />
              <RouteHotelsTable />
            </Col>
          </ManagementRouteRoomContainer>
        </Guard>
      )}
      {mode === "view" && (
        <ManagementRouteRoomContainer justify={"center"} gutter={[0, 24]}>
          <Col xs={24} md={23} className="content">
            <RouteHotelsTable />
          </Col>
        </ManagementRouteRoomContainer>
      )}
    </>
  );
};

export default RouteHotels;
