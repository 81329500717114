import { FC } from "react";
import { Col } from "antd";
import DataEntryHeader from "src/components/dataEntryHeader";
import useLanguage from "src/store/language";
import RoomsDataProvider from "./context/RoomsData";
import { RoomsContainer } from "./style";
import AddRoom from "./components/form/AddForm";
import RoomTable from "./components/table/Table";
import { Guard } from "src/components/Guard";
import { AddRoomPath } from "src/services/BasicInformation/baseGuardPath";

const Rooms: FC = () => {
  const { words } = useLanguage();
  return (
    <RoomsDataProvider>
      <Guard action={AddRoomPath}>
        <DataEntryHeader title={words.rooms.roomsTitle} />
        <RoomsContainer justify={"center"} gutter={[0, 24]}>
          <Col span={23} className="content">
            <AddRoom />
            <RoomTable />
          </Col>
        </RoomsContainer>
      </Guard>
    </RoomsDataProvider>
  );
};

export default Rooms;
