import { Row } from "antd";
import styled from "styled-components";

export const ManagementRouteRoomContainer = styled(Row)`
  padding: 32px 0;
  .header {
    border-bottom: 1px solid var(--shadow, #dadeec);
    display: flex;
    padding: 0 24px 24px 24px;
    align-items: center;
    gap: 10px;
    color: var(--text-3, #5d6881);
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
  }
  .content {
    border-radius: 20px;
    background: var(--0, #fff);
    box-shadow: 2px 6px 16px 0px rgba(0, 0, 0, 0.18);
    padding: 24px 32px;
  }
  .title {
    color: var(--text, #1f1f1f);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .description {
    color: #233069;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .footer-box {
    width: 100%;
    display: inline-flex;
    justify-content: end;
    align-items: center;
  }
  .ant-input {
    color: #000000;
  }
  .add-hotel-form-title {
    color: var(--color-Light-Mode-Text-Title, #233069);
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .hotel-table-title {
    color: var(--color-Light-Mode-Text-Primary-text, #1f1f1f);
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .react-datepicker__input-container {
    background: var(--color-Light-Mode-Disable-Selected, #e9eaf1);
    color: var(--color-Light-Mode-Text-Primary-text, #1f1f1f);
    cursor: not-allowed;
  }
  .react-datepicker-wrapper input {
    cursor: not-allowed;
  }
  .react-datepicker__close-icon,
  .react-datepicker__calendar-icon {
    display: none;
  }
  .calculated-date {
    width: 100%;
    height: 51px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background-color: #e9eaf1;
    padding-right: 12px;
    padding-left: 12px;
  }
`;
