import { FC } from "react";
import { Col, Spin } from "antd";
import ViewCompanionLeader from "./components/ViewCompanionLeader";
import AddEditCompanionLeader from "./components/AddEditCompanionLeader";
import { ManagementCompanionLeaderContainer } from "./style";
import { useManagementCompanionLeaderData } from "./context";
import { Guard } from "src/components/Guard";
import {
  AddCompanionLeaderPath,
  GetCompanionLeaderPath,
} from "src/services/TourManagement/guardPath";

const ManagementCompanionLeader: FC = () => {
  const {
    states: { mode, dataLoading },
  } = useManagementCompanionLeaderData();

  return (
    <>
      {(mode === "add" || mode === "edit") && (
        <Guard action={[GetCompanionLeaderPath, AddCompanionLeaderPath]}>
          <ManagementCompanionLeaderContainer
            justify={"center"}
            gutter={[0, 24]}
          >
            <Col xs={24} md={16} className="content">
              {dataLoading ? (
                <div className="flex w-full h-full justify-center items-center">
                  <Spin />
                </div>
              ) : (
                <AddEditCompanionLeader />
              )}
            </Col>
          </ManagementCompanionLeaderContainer>
        </Guard>
      )}
      {mode === "view" && (
        <ManagementCompanionLeaderContainer justify={"center"} gutter={[0, 24]}>
          <Col xs={24} md={18} className="content">
            {dataLoading ? (
              <div className="flex w-full h-full justify-center items-center">
                <Spin />
              </div>
            ) : (
              <ViewCompanionLeader />
            )}
          </Col>
        </ManagementCompanionLeaderContainer>
      )}
    </>
  );
};

export default ManagementCompanionLeader;
