import { FC } from "react";
import { Col } from "antd";
import { useInsuranceAttachmentData } from "./context";
import InsuranceFileForm from "./components/form/InsuranceFile";
import InsuranceInfo from "./components/insuranceInfo";
import AttachmentTable from "./components/table/FileTable";
import { ManagementInsuranceAttachmentContainer } from "./style";
import { Guard } from "src/components/Guard";
import {
  AddInsuranceAttachmentPath,
  GetAllInsuranceAttachmentPath,
} from "src/services/TourManagement/guardPath";

const InsuranceFile: FC = () => {
  const {
    states: { mode },
  } = useInsuranceAttachmentData();

  return (
    <>
      {(mode === "add" || mode === "edit") && (
        <Guard
          action={[AddInsuranceAttachmentPath, GetAllInsuranceAttachmentPath]}
        >
          <ManagementInsuranceAttachmentContainer
            justify={"center"}
            gutter={[0, 24]}
          >
            <Col xs={24} md={23} className="content">
              <InsuranceFileForm />
              <AttachmentTable />
            </Col>
          </ManagementInsuranceAttachmentContainer>
        </Guard>
      )}
      {mode === "view" && (
        <ManagementInsuranceAttachmentContainer
          justify={"center"}
          gutter={[0, 24]}
        >
          <Col xs={24} md={23} className="content">
            <InsuranceInfo />
            <AttachmentTable />
          </Col>
        </ManagementInsuranceAttachmentContainer>
      )}
    </>
  );
};

export default InsuranceFile;
