import { FC } from "react";
import { Col } from "antd";
import AddEditRoutePlaces from "./components/form/AddEditRoutePlaces";
import RoutePlacesTable from "./components/table/RoutePlacesTable";
import { ManagementRoutePlaceContainer } from "./style";
import { useRoutePlacesData } from "./context";
import { Guard } from "src/components/Guard";
import {
  AddRoutePlaceOfInterestPath,
  GetAllRoutePlaceOfInterestPath,
} from "src/services/TourManagement/guardPath";

const RoutePlaces: FC = () => {
  const {
    states: { mode },
  } = useRoutePlacesData();

  return (
    <>
      {(mode === "add" || mode === "edit") && (
        <Guard
          action={[AddRoutePlaceOfInterestPath, GetAllRoutePlaceOfInterestPath]}
        >
          <ManagementRoutePlaceContainer justify={"center"} gutter={[0, 24]}>
            <Col xs={24} md={23} className="content">
              <AddEditRoutePlaces />
              <RoutePlacesTable />
            </Col>
          </ManagementRoutePlaceContainer>
        </Guard>
      )}
      {mode === "view" && (
        <ManagementRoutePlaceContainer justify={"center"} gutter={[0, 24]}>
          <Col xs={24} md={23} className="content">
            <RoutePlacesTable />
          </Col>
        </ManagementRoutePlaceContainer>
      )}
    </>
  );
};

export default RoutePlaces;
