import { FC } from "react";
import { Col, Spin } from "antd";
import { ManagementBasicInfoContainer } from "./style";
import { useManagementBasicInfoData } from "./context";
import ViewBasicInfo from "./components/ViewBasicInfo";
import EditBasicInfo from "./components/EditBasicInfo";
import { Guard } from "src/components/Guard";
import { EditTourPath } from "src/services/TourManagement/guardPath";

const ManagementRoute: FC = () => {
  const {
    states: { mode, dataLoading },
  } = useManagementBasicInfoData();

  return (
    <>
      {(mode === "add" || mode === "edit") && (
        <Guard action={EditTourPath}>
          <ManagementBasicInfoContainer justify={"center"} gutter={[0, 24]}>
            <Col xs={24} md={23} className="content">
              {dataLoading ? (
                <Spin size="large" className="mt-[30px]" />
              ) : (
                <EditBasicInfo />
              )}
            </Col>
          </ManagementBasicInfoContainer>
        </Guard>
      )}
      {mode === "view" && (
        <ManagementBasicInfoContainer justify={"center"} gutter={[0, 24]}>
          <Col xs={24} md={16} className="content">
            {dataLoading ? (
              <Spin size="large" className="mt-[30px]" />
            ) : (
              <ViewBasicInfo />
            )}
          </Col>
        </ManagementBasicInfoContainer>
      )}
    </>
  );
};

export default ManagementRoute;
