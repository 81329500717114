import { FC } from "react";
import { Col } from "antd";
import { ManagementDriverContainer } from "./style";
import { useManagementDriverData } from "./context";
import ViewDriverCar from "./components/view";
import AddEditDriver from "./components/add-edit";
import { Guard } from "src/components/Guard";
import {
  AddDriverPath,
  GetDriverPath,
} from "src/services/TourManagement/guardPath";

const ManagementDriver: FC = () => {
  const {
    states: { mode },
  } = useManagementDriverData();

  return (
    <>
      {(mode === "add" || mode === "edit") && (
        <Guard action={[GetDriverPath, AddDriverPath]}>
          <ManagementDriverContainer justify={"center"} gutter={[0, 24]}>
            <Col xs={24} md={23} className="content">
              <AddEditDriver />
            </Col>
          </ManagementDriverContainer>
        </Guard>
      )}
      {mode === "view" && (
        <ManagementDriverContainer justify={"center"} gutter={[0, 24]}>
          <Col xs={24} md={18} className="content">
            <ViewDriverCar />
          </Col>
        </ManagementDriverContainer>
      )}
    </>
  );
};

export default ManagementDriver;
