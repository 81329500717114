import { IPath } from "src/models/interfaces/IPath";
import * as GuardPath from "../../../services/TourManagement/guardPath";
import * as baseGuardPath from "../../../services/BasicInformation/baseGuardPath";
import * as systemGuardPath from "../../../services/System/guardPath";
import * as userGuardPath from "../../../services/UserManagement/guardPath";

export const ManagementAddTourGuardPaths: IPath[] = [GuardPath.AddTourPath];

export const ManagementEditTourGuardPaths: IPath[] = [
  GuardPath.GetTourByIdPath,
  GuardPath.AddTourPath,
  GuardPath.EditTourPath,
];

export const ManagementTouristInfoGuardPaths: IPath[] = [
  GuardPath.GetAllTourTouristPath,
  GuardPath.AddTourTouristPath,
  GuardPath.DeleteTourTouristPath,
  GuardPath.EditTourTouristPath,
];

export const ManagementTourTimeGuardPaths: IPath[] = [
  GuardPath.GetTourTimePath,
  GuardPath.AddTourTimePath,
  GuardPath.EditTourTimePath,
  GuardPath.DeleteTourTimePath,
];

export const ManagementRoutesGuardPaths: IPath[] = [
  GuardPath.GetAllRoutePath,
  GuardPath.AddRoutePath,
  GuardPath.AddRouteRoomPath,
  GuardPath.GetAllRouteRoomPath,
  GuardPath.AddRoutePlaceOfInterestPath,
  GuardPath.GetAllRoutePlaceOfInterestPath,
  GuardPath.EditRoutePath,
  GuardPath.DeleteRoutePath,
];

export const ManagementRoutesPlacesGuardPaths: IPath[] = [
  GuardPath.EditRoutePlaceOfInterestPath,
  GuardPath.DeleteRoutePlaceOfInterestPath,
  GuardPath.AddRoutePlaceOfInterestPath,
  GuardPath.GetAllRoutePlaceOfInterestPath,
];

export const ManagementRoutesRoomsGuardPaths: IPath[] = [
  GuardPath.AddRouteRoomPath,
  GuardPath.GetAllRouteRoomPath,
  GuardPath.EditRouteRoomPath,
  GuardPath.DeleteRouteRoomPath,
];

export const ManagementSimCardGuardPaths: IPath[] = [
  GuardPath.GetSimCardPath,
  GuardPath.AddSimCardPath,
  GuardPath.DeleteSimCardPath,
  GuardPath.EditSimCardPath,
];

export const ManagementAssignSimCardGuardPaths: IPath[] = [
  GuardPath.AddSimCardTourTouristPath,
  GuardPath.GetAllSimCardTourTouristPath,
  GuardPath.EditSimCardTourTouristPath,
  GuardPath.DeleteSimCardTourTouristPath,
];

export const ManagementInsuranceGuardPaths: IPath[] = [
  GuardPath.AddInsurancePath,
  GuardPath.GetAllInsurancePath,
  GuardPath.GetAllInsuranceAttachmentPath,
  GuardPath.EditInsurancePath,
  GuardPath.DeleteInsurancePath,
  GuardPath.AddInsuranceAttachmentPath,
];

export const ManagementInsuranceAttachmentGuardPaths: IPath[] = [
  GuardPath.AddInsuranceAttachmentPath,
  GuardPath.GetAllInsuranceAttachmentPath,
  GuardPath.DownloadInsuranceAttachmentPath,
  GuardPath.EditInsuranceAttachmentPath,
  GuardPath.DeleteInsuranceAttachmentPath,
];

export const ManagementDomesticFlightGuardPaths: IPath[] = [
  GuardPath.AddDomesticFlightPath,
  GuardPath.GetAllDomesticFlightPath,
  GuardPath.EditDomesticFlightPath,
  GuardPath.DeleteDomesticFlightPath,
];

export const ManagementFileGuardPaths: IPath[] = [
  GuardPath.AddTourAttachmentPath,
  GuardPath.GetAllTourAttachmentPath,
  GuardPath.DownloadTourAttachmentPath,
  GuardPath.EditTourAttachmentPath,
  GuardPath.DeleteTourAttachmentPath,
];

export const ManagementTranslatorGuardPaths: IPath[] = [
  GuardPath.AddTranslatorPath,
  GuardPath.GetTranslatorPath,
  GuardPath.DeleteTranslatorPath,
  GuardPath.EditTranslatorPath,
];

export const ManagementLeaderGuardPaths: IPath[] = [
  GuardPath.GetLeaderPath,
  GuardPath.AddLeaderPath,
  GuardPath.DeleteLeaderPath,
  GuardPath.EditLeaderPath,
];

export const ManagementDriverGuardPaths: IPath[] = [
  GuardPath.GetDriverPath,
  GuardPath.AddDriverPath,
  GuardPath.DeleteDriverPath,
  GuardPath.EditDriverPath,
];

export const ManagementCompanionLeaderGuardPaths: IPath[] = [
  GuardPath.GetCompanionLeaderPath,
  GuardPath.AddCompanionLeaderPath,
  GuardPath.DeleteCompanionLeaderPath,
  GuardPath.EditCompanionLeaderPath,
];

export const BaseInfoAddCountryPaths: IPath[] = [
  baseGuardPath.AddCountryPath,
  baseGuardPath.UpdateCountryPath,
  baseGuardPath.DeleteCountryPath,
  baseGuardPath.AllCountryPath,
];

export const BaseInfoAddCityPaths: IPath[] = [
  baseGuardPath.AddCityPath,
  baseGuardPath.AllCityPath,
  baseGuardPath.UpdateCityPath,
  baseGuardPath.DeleteCityPath,
];

export const BaseInfoPlacesPaths: IPath[] = [
  baseGuardPath.AddPlaceOfInterestPath,
  baseGuardPath.UpdatePlaceOfInterestPath,
  baseGuardPath.DeletePlaceOfInterestPath,
  baseGuardPath.AllPlaceOfInterestsPath,
];

export const BaseInfoHotelsPaths: IPath[] = [
  baseGuardPath.AddHotelPath,
  baseGuardPath.AddHotelResponderDetailPath,
  baseGuardPath.AddHotelResponderPath,
  baseGuardPath.AllHotelsPath,
  baseGuardPath.UpdateHotelPath,
  baseGuardPath.DeleteHotelPath,
];

export const BaseInfoRoomsPaths: IPath[] = [
  baseGuardPath.AddRoomPath,
  baseGuardPath.UpdateRoomPath,
  baseGuardPath.DeleteRoomPath,
  baseGuardPath.AllRoomPath,
];

export const BaseInfoBrokerPaths: IPath[] = [
  baseGuardPath.AddBrokerPath,
  baseGuardPath.UpdateBrokerPath,
  baseGuardPath.DeleteBrokerPath,
  baseGuardPath.AllBrokerPath,
];

export const RoleGuardPath: IPath[] = [systemGuardPath.NewRolePath];

export const AssignActionToRoleGuardPath: IPath[] = [
  systemGuardPath.AssignRoleToActionPath,
];

export const UserGuardPath: IPath[] = [userGuardPath.newUserPath];

export const AssignRoleToUserGuardPath: IPath[] = [
  userGuardPath.EnablePath,
  userGuardPath.DisablePath,
  userGuardPath.userReportPath,
  userGuardPath.AddUserRolePath,
];
