import { FC } from "react";
import { Col } from "antd";
import { ManagementSimcardContainer } from "./style";
import { useManagementSimcardData } from "./context";
import ViewSimcard from "./components/view/ViewSimcard";
import AddEditSimcardDataProvider from "./components/add-edit/context";
import SimcardAddEdit from "./components/add-edit";
import { Guard } from "src/components/Guard";
import {
  AddSimCardPath,
  GetSimCardPath,
} from "src/services/TourManagement/guardPath";

const ManagementSimcard: FC = () => {
  const {
    states: { mode },
  } = useManagementSimcardData();

  return (
    <AddEditSimcardDataProvider>
      <>
        {(mode === "add" || mode === "edit") && (
          <Guard action={[GetSimCardPath, AddSimCardPath]}>
            <ManagementSimcardContainer justify={"center"} gutter={[0, 24]}>
              <Col xs={24} md={23} className="content">
                <SimcardAddEdit />
              </Col>
            </ManagementSimcardContainer>
          </Guard>
        )}
        {mode === "view" && (
          <ManagementSimcardContainer justify={"center"} gutter={[0, 24]}>
            <Col xs={24} md={16} className="content">
              <ViewSimcard />
            </Col>
          </ManagementSimcardContainer>
        )}
      </>
    </AddEditSimcardDataProvider>
  );
};

export default ManagementSimcard;
