import { Flex } from "antd";
import { FC } from "react";
import { IHotel } from "src/services/BasicInformation/models";
import useLanguage from "src/store/language";
import { useHotelsData } from "src/modules/hotels/context/HotelsData";
import { HotelsViewMode } from "src/modules/hotels/models/enums";
import { useHotelsTableData } from "../context";
import { Guard } from "src/components/Guard";
import {
  DeleteHotelPath,
  UpdateHotelPath,
} from "src/services/BasicInformation/baseGuardPath";

interface ITableActionsProps {
  item: IHotel;
}

const TableActions: FC<ITableActionsProps> = ({ item }) => {
  const { words } = useLanguage();

  const {
    dispatches: { setSelectedHotel, setViewMode },
  } = useHotelsData();

  const { handleDeleteId } = useHotelsTableData();

  const handleSelectPlace = (mode: HotelsViewMode) => {
    setSelectedHotel(item);
    setViewMode(mode);
  };

  return (
    <>
      <Flex justify="center" className="gap-[16px]">
        <Guard action={UpdateHotelPath}>
          <div
            className="edit-action"
            onClick={() => handleSelectPlace(HotelsViewMode.Edit)}
          >
            <span className="material-icons text-[14px]">edit_note</span>
            <span>{words.global.editAction}</span>
          </div>
        </Guard>
        <Guard action={DeleteHotelPath}>
          <div
            className="delete-action"
            onClick={() => handleDeleteId(item.id)}
          >
            <span className="material-icons text-[14px]">delete_forever</span>
            <span>{words.global.delete}</span>
          </div>
        </Guard>
      </Flex>
    </>
  );
};

export default TableActions;
