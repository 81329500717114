import { FC } from "react";
import { Flex } from "antd";
import { useCountryContext } from "../context";
import useLanguage from "src/store/language";
import { ICountry } from "src/services/BasicInformation/models";
import { Guard } from "src/components/Guard";
import {
  DeleteCountryPath,
  UpdateCountryPath,
} from "src/services/BasicInformation/baseGuardPath";

interface ITableActionsProps {
  item: ICountry;
}

const TableActions: FC<ITableActionsProps> = ({ item }) => {
  const { setEditCountry, handleDeleteId } = useCountryContext();
  const { words } = useLanguage();

  return (
    <>
      <Flex justify="center" className="gap-[16px]">
        <Guard action={UpdateCountryPath}>
          <div className="edit-action" onClick={() => setEditCountry(item)}>
            <span className="material-icons text-[#0C9D61]  text-[16px]">
              edit_note
            </span>
            <span>{words.global.editAction}</span>
          </div>
        </Guard>
        <Guard action={DeleteCountryPath}>
          <div
            className="delete-action"
            onClick={() => handleDeleteId(item.id)}
          >
            <span className="material-icons text-[#ec2d30] text-[14px]">
              delete_forever
            </span>
            <span>{words.global.delete}</span>
          </div>
        </Guard>
      </Flex>
    </>
  );
};

export default TableActions;
