import { IPath } from "src/models/interfaces/IPath";

export const TourManagementPaths: IPath[] = [
  { path: "api/TourManagement/GetDashboardInfo", httpMethod: "GET" },
  { path: "api/TourManagement/AddTour", httpMethod: "POST" },
  { path: "api/TourManagement/{TourId}/GetTourById", httpMethod: "GET" },
  { path: "api/TourManagement/{TourId}/GetTourManagement", httpMethod: "GET" },
  { path: "api/TourManagement/GetAllTour", httpMethod: "GET" },
  { path: "api/TourManagement/GetAllToursCode", httpMethod: "GET" },
  { path: "api/TourManagement/{TourId}/EditTour", httpMethod: "PUT" },
  { path: "api/TourManagement/{TourId}/DeleteTour", httpMethod: "DELETE" },
  { path: "api/TourManagement/{TourId}/AddDomesticFlight", httpMethod: "POST" },
  {
    path: "api/TourManagement/{TourId}/GetAllDomesticFlight",
    httpMethod: "GET",
  },
  {
    path: "api/TourManagement/{DomesticFlightId}/GetDomesticFlightById",
    httpMethod: "GET",
  },
  {
    path: "api/TourManagement/{DomesticFlightId}/EditDomesticFlight",
    httpMethod: "PUT",
  },
  {
    path: "api/TourManagement/{DomesticFlightId}/DeleteDomesticFlight",
    httpMethod: "DELETE",
  },
  { path: "api/TourManagement/{TourId}/AddTourTime", httpMethod: "POST" },
  { path: "api/TourManagement/{TourId}/GetTourTime", httpMethod: "GET" },
  { path: "api/TourManagement/{TourTimeId}/EditTourTime", httpMethod: "PUT" },
  {
    path: "api/TourManagement/{TourTimeId}/DeleteTourTime",
    httpMethod: "DELETE",
  },
  {
    path: "api/TourManagement/{TourId}/AddCompanionLeader",
    httpMethod: "POST",
  },
  { path: "api/TourManagement/{TourId}/GetCompanionLeader", httpMethod: "GET" },
  {
    path: "api/TourManagement/{CompanionLeaderId}/EditCompanionLeader",
    httpMethod: "PUT",
  },
  {
    path: "api/TourManagement/{CompanionLeaderId}/DeleteCompanionLeader",
    httpMethod: "DELETE",
  },
  { path: "api/TourManagement/{TourId}/AddTranslator", httpMethod: "POST" },
  { path: "api/TourManagement/{TourId}/GetTranslator", httpMethod: "GET" },
  {
    path: "api/TourManagement/{TranslatorId}/EditTranslator",
    httpMethod: "PUT",
  },
  {
    path: "api/TourManagement/{TranslatorId}/DeleteTranslator",
    httpMethod: "DELETE",
  },
  { path: "api/TourManagement/{TourId}/AddDriver", httpMethod: "POST" },
  { path: "api/TourManagement/{TourId}/GetDriver", httpMethod: "GET" },
  { path: "api/TourManagement/{DriverId}/EditDriver", httpMethod: "PUT" },
  { path: "api/TourManagement/{DriverId}/DeleteDriver", httpMethod: "DELETE" },
  { path: "api/TourManagement/{TourId}/AddSimCard", httpMethod: "POST" },
  { path: "api/TourManagement/{TourId}/GetSimCard", httpMethod: "GET" },
  { path: "api/TourManagement/{SimCardId}/EditSimCard", httpMethod: "PUT" },
  {
    path: "api/TourManagement/{SimCardId}/DeleteSimCard",
    httpMethod: "DELETE",
  },
  { path: "api/TourManagement/{TourId}/AddTourTourist", httpMethod: "POST" },
  {
    path: "api/TourManagement/{TourId}/AddListTourTourist",
    httpMethod: "POST",
  },
  { path: "api/TourManagement/{TourId}/GetAllTourTourist", httpMethod: "GET" },
  {
    path: "api/TourManagement/{TourTouristId}/EditTourTourist",
    httpMethod: "PUT",
  },
  {
    path: "api/TourManagement/{TourTouristId}/DeleteTourTourist",
    httpMethod: "DELETE",
  },
  {
    path: "api/TourManagement/{TourId}/AddSimCardTourTourist",
    httpMethod: "POST",
  },
  {
    path: "api/TourManagement/{TourId}/GetAllSimCardTourTourist",
    httpMethod: "GET",
  },
  {
    path: "api/TourManagement/{SimCardTourTouristId}/GetSimCardTourTouristById",
    httpMethod: "GET",
  },
  {
    path: "api/TourManagement/{TourTouristId}/EditSimCardTourTourist",
    httpMethod: "PUT",
  },
  {
    path: "api/TourManagement/{TourTouristId}/DeleteSimCardTourTourist",
    httpMethod: "DELETE",
  },
  {
    path: "api/TourManagement/{TourId}/GetAllTouristsByTour",
    httpMethod: "GET",
  },
  {
    path: "api/TourManagement/{PassportNo}/GetTouristByPassport",
    httpMethod: "GET",
  },
  { path: "api/TourManagement/{TourId}/AddLeader", httpMethod: "POST" },
  {
    path: "api/TourManagement/{TourId}/LeaderNationalCardImage",
    httpMethod: "GET",
  },
  { path: "api/TourManagement/{TourId}/LeaderCardImage", httpMethod: "GET" },
  { path: "api/TourManagement/{TourId}/GetLeader", httpMethod: "GET" },
  { path: "api/TourManagement/{LeaderId}/EditLeader", httpMethod: "PUT" },
  { path: "api/TourManagement/{LeaderId}/DeleteLeader", httpMethod: "DELETE" },
  { path: "api/TourManagement/{TourId}/AddInsurance", httpMethod: "POST" },
  { path: "api/TourManagement/{TourId}/GetAllInsurance", httpMethod: "GET" },
  {
    path: "api/TourManagement/{InsuranceId}/GetInsuranceById",
    httpMethod: "GET",
  },
  { path: "api/TourManagement/{InsuranceId}/EditInsurance", httpMethod: "PUT" },
  {
    path: "api/TourManagement/{InsuranceId}/DeleteInsurance",
    httpMethod: "DELETE",
  },
  {
    path: "api/TourManagement/{InsuranceId}/AddInsuranceAttachment",
    httpMethod: "POST",
  },
  {
    path: "api/TourManagement/{InsuranceId}/GetAllInsuranceAttachment",
    httpMethod: "GET",
  },
  {
    path: "api/TourManagement/{InsuranceAttachmentId}/GetInsuranceAttachmentById",
    httpMethod: "GET",
  },
  {
    path: "api/TourManagement/{InsuranceAttachmentId}/DownloadInsuranceAttachment",
    httpMethod: "GET",
  },
  {
    path: "api/TourManagement/{InsuranceAttachmentId}/EditInsuranceAttachment",
    httpMethod: "PUT",
  },
  {
    path: "api/TourManagement/{InsuranceAttachmentId}/DeleteInsuranceAttachment",
    httpMethod: "DELETE",
  },
  { path: "api/TourManagement/{TourId}/AddTourAttachment", httpMethod: "POST" },
  {
    path: "api/TourManagement/{TourId}/GetAllTourAttachment",
    httpMethod: "GET",
  },
  {
    path: "api/TourManagement/{TourAttachmentId}/GetTourAttachmentById",
    httpMethod: "GET",
  },
  {
    path: "api/TourManagement/{TourAttachmentId}/DownloadTourAttachment",
    httpMethod: "GET",
  },
  {
    path: "api/TourManagement/{TourAttachmentId}/EditTourAttachment",
    httpMethod: "PUT",
  },
  {
    path: "api/TourManagement/{TourAttachmentId}/DeleteTourAttachment",
    httpMethod: "DELETE",
  },
  { path: "api/TourManagement/{TourId}/AddRoute", httpMethod: "POST" },
  { path: "api/TourManagement/{TourId}/GetAllRoute", httpMethod: "GET" },
  { path: "api/TourManagement/{RouteId}/GetRouteById", httpMethod: "GET" },
  { path: "api/TourManagement/{RouteId}/EditRoute", httpMethod: "PUT" },
  { path: "api/TourManagement/{RouteId}/DeleteRoute", httpMethod: "DELETE" },
  { path: "api/TourManagement/{RouteId}/AddRouteRoom", httpMethod: "POST" },
  { path: "api/TourManagement/{RouteId}/GetAllRouteRoom", httpMethod: "GET" },
  { path: "api/TourManagement/{RouteRoomId}/EditRouteRoom", httpMethod: "PUT" },
  {
    path: "api/TourManagement/{RouteRoomId}/DeleteRouteRoom",
    httpMethod: "DELETE",
  },
  {
    path: "api/TourManagement/{RouteId}/AddRoutePlaceOfInterest",
    httpMethod: "POST",
  },
  {
    path: "api/TourManagement/{RouteId}/GetAllRoutePlaceOfInterest",
    httpMethod: "GET",
  },
  {
    path: "api/TourManagement/{RoutePlaceOfInterestId}/EditRoutePlaceOfInterest",
    httpMethod: "PUT",
  },
  {
    path: "api/TourManagement/{RoutePlaceOfInterestId}/DeleteRoutePlaceOfInterest",
    httpMethod: "DELETE",
  },
];

export const GetDashboardInfoPath: IPath = {
  path: "api/TourManagement/GetDashboardInfo",
  httpMethod: "GET",
};
export const AddTourPath: IPath = {
  path: "api/TourManagement/AddTour",
  httpMethod: "POST",
};
export const GetTourByIdPath: IPath = {
  path: "api/TourManagement/{TourId}/GetTourById",
  httpMethod: "GET",
};
export const GetTourManagementPath: IPath = {
  path: "api/TourManagement/{TourId}/GetTourManagement",
  httpMethod: "GET",
};
export const GetAllTourPath: IPath = {
  path: "api/TourManagement/GetAllTour",
  httpMethod: "GET",
};
export const GetAllToursCodePath: IPath = {
  path: "api/TourManagement/GetAllToursCode",
  httpMethod: "GET",
};
export const EditTourPath: IPath = {
  path: "api/TourManagement/{TourId}/EditTour",
  httpMethod: "PUT",
};
export const DeleteTourPath: IPath = {
  path: "api/TourManagement/{TourId}/DeleteTour",
  httpMethod: "DELETE",
};
export const AddDomesticFlightPath: IPath = {
  path: "api/TourManagement/{TourId}/AddDomesticFlight",
  httpMethod: "POST",
};
export const GetAllDomesticFlightPath: IPath = {
  path: "api/TourManagement/{TourId}/GetAllDomesticFlight",
  httpMethod: "GET",
};
export const GetDomesticFlightByIdPath: IPath = {
  path: "api/TourManagement/{DomesticFlightId}/GetDomesticFlightById",
  httpMethod: "GET",
};
export const EditDomesticFlightPath: IPath = {
  path: "api/TourManagement/{DomesticFlightId}/EditDomesticFlight",
  httpMethod: "PUT",
};
export const DeleteDomesticFlightPath: IPath = {
  path: "api/TourManagement/{DomesticFlightId}/DeleteDomesticFlight",
  httpMethod: "DELETE",
};
export const AddTourTimePath: IPath = {
  path: "api/TourManagement/{TourId}/AddTourTime",
  httpMethod: "POST",
};
export const GetTourTimePath: IPath = {
  path: "api/TourManagement/{TourId}/GetTourTime",
  httpMethod: "GET",
};
export const EditTourTimePath: IPath = {
  path: "api/TourManagement/{TourTimeId}/EditTourTime",
  httpMethod: "PUT",
};
export const DeleteTourTimePath: IPath = {
  path: "api/TourManagement/{TourTimeId}/DeleteTourTime",
  httpMethod: "DELETE",
};
export const AddCompanionLeaderPath: IPath = {
  path: "api/TourManagement/{TourId}/AddCompanionLeader",
  httpMethod: "POST",
};
export const GetCompanionLeaderPath: IPath = {
  path: "api/TourManagement/{TourId}/GetCompanionLeader",
  httpMethod: "GET",
};
export const EditCompanionLeaderPath: IPath = {
  path: "api/TourManagement/{CompanionLeaderId}/EditCompanionLeader",
  httpMethod: "PUT",
};
export const DeleteCompanionLeaderPath: IPath = {
  path: "api/TourManagement/{CompanionLeaderId}/DeleteCompanionLeader",
  httpMethod: "DELETE",
};
export const AddTranslatorPath: IPath = {
  path: "api/TourManagement/{TourId}/AddTranslator",
  httpMethod: "POST",
};
export const GetTranslatorPath: IPath = {
  path: "api/TourManagement/{TourId}/GetTranslator",
  httpMethod: "GET",
};
export const EditTranslatorPath: IPath = {
  path: "api/TourManagement/{TranslatorId}/EditTranslator",
  httpMethod: "PUT",
};
export const DeleteTranslatorPath: IPath = {
  path: "api/TourManagement/{TranslatorId}/DeleteTranslator",
  httpMethod: "DELETE",
};
export const AddDriverPath: IPath = {
  path: "api/TourManagement/{TourId}/AddDriver",
  httpMethod: "POST",
};
export const GetDriverPath: IPath = {
  path: "api/TourManagement/{TourId}/GetDriver",
  httpMethod: "GET",
};
export const EditDriverPath: IPath = {
  path: "api/TourManagement/{DriverId}/EditDriver",
  httpMethod: "PUT",
};
export const DeleteDriverPath: IPath = {
  path: "api/TourManagement/{DriverId}/DeleteDriver",
  httpMethod: "DELETE",
};
export const AddSimCardPath: IPath = {
  path: "api/TourManagement/{TourId}/AddSimCard",
  httpMethod: "POST",
};
export const GetSimCardPath: IPath = {
  path: "api/TourManagement/{TourId}/GetSimCard",
  httpMethod: "GET",
};
export const EditSimCardPath: IPath = {
  path: "api/TourManagement/{SimCardId}/EditSimCard",
  httpMethod: "PUT",
};
export const DeleteSimCardPath: IPath = {
  path: "api/TourManagement/{SimCardId}/DeleteSimCard",
  httpMethod: "DELETE",
};
export const AddTourTouristPath: IPath = {
  path: "api/TourManagement/{TourId}/AddTourTourist",
  httpMethod: "POST",
};
export const AddListTourTouristPath: IPath = {
  path: "api/TourManagement/{TourId}/AddListTourTourist",
  httpMethod: "POST",
};
export const GetAllTourTouristPath: IPath = {
  path: "api/TourManagement/{TourId}/GetAllTourTourist",
  httpMethod: "GET",
};
export const EditTourTouristPath: IPath = {
  path: "api/TourManagement/{TourTouristId}/EditTourTourist",
  httpMethod: "PUT",
};
export const DeleteTourTouristPath: IPath = {
  path: "api/TourManagement/{TourTouristId}/DeleteTourTourist",
  httpMethod: "DELETE",
};
export const AddSimCardTourTouristPath: IPath = {
  path: "api/TourManagement/{TourId}/AddSimCardTourTourist",
  httpMethod: "POST",
};
export const GetAllSimCardTourTouristPath: IPath = {
  path: "api/TourManagement/{TourId}/GetAllSimCardTourTourist",
  httpMethod: "GET",
};
export const GetSimCardTourTouristByIdPath: IPath = {
  path: "api/TourManagement/{SimCardTourTouristId}/GetSimCardTourTouristById",
  httpMethod: "GET",
};
export const EditSimCardTourTouristPath: IPath = {
  path: "api/TourManagement/{TourTouristId}/EditSimCardTourTourist",
  httpMethod: "PUT",
};
export const DeleteSimCardTourTouristPath: IPath = {
  path: "api/TourManagement/{TourTouristId}/DeleteSimCardTourTourist",
  httpMethod: "DELETE",
};
export const GetAllTouristsByTourPath: IPath = {
  path: "api/TourManagement/{TourId}/GetAllTouristsByTour",
  httpMethod: "GET",
};
export const GetTouristByPassportPath: IPath = {
  path: "api/TourManagement/{PassportNo}/GetTouristByPassport",
  httpMethod: "GET",
};
export const AddLeaderPath: IPath = {
  path: "api/TourManagement/{TourId}/AddLeader",
  httpMethod: "POST",
};
export const LeaderNationalCardImagePath: IPath = {
  path: "api/TourManagement/{TourId}/LeaderNationalCardImage",
  httpMethod: "GET",
};
export const LeaderCardImagePath: IPath = {
  path: "api/TourManagement/{TourId}/LeaderCardImage",
  httpMethod: "GET",
};
export const GetLeaderPath: IPath = {
  path: "api/TourManagement/{TourId}/GetLeader",
  httpMethod: "GET",
};
export const EditLeaderPath: IPath = {
  path: "api/TourManagement/{LeaderId}/EditLeader",
  httpMethod: "PUT",
};
export const DeleteLeaderPath: IPath = {
  path: "api/TourManagement/{LeaderId}/DeleteLeader",
  httpMethod: "DELETE",
};
export const AddInsurancePath: IPath = {
  path: "api/TourManagement/{TourId}/AddInsurance",
  httpMethod: "POST",
};
export const GetAllInsurancePath: IPath = {
  path: "api/TourManagement/{TourId}/GetAllInsurance",
  httpMethod: "GET",
};
export const GetInsuranceByIdPath: IPath = {
  path: "api/TourManagement/{InsuranceId}/GetInsuranceById",
  httpMethod: "GET",
};
export const EditInsurancePath: IPath = {
  path: "api/TourManagement/{InsuranceId}/EditInsurance",
  httpMethod: "PUT",
};
export const DeleteInsurancePath: IPath = {
  path: "api/TourManagement/{InsuranceId}/DeleteInsurance",
  httpMethod: "DELETE",
};
export const AddInsuranceAttachmentPath: IPath = {
  path: "api/TourManagement/{InsuranceId}/AddInsuranceAttachment",
  httpMethod: "POST",
};
export const GetAllInsuranceAttachmentPath: IPath = {
  path: "api/TourManagement/{InsuranceId}/GetAllInsuranceAttachment",
  httpMethod: "GET",
};
export const GetInsuranceAttachmentByIdPath: IPath = {
  path: "api/TourManagement/{InsuranceAttachmentId}/GetInsuranceAttachmentById",
  httpMethod: "GET",
};
export const DownloadInsuranceAttachmentPath: IPath = {
  path: "api/TourManagement/{InsuranceAttachmentId}/DownloadInsuranceAttachment",
  httpMethod: "GET",
};
export const EditInsuranceAttachmentPath: IPath = {
  path: "api/TourManagement/{InsuranceAttachmentId}/EditInsuranceAttachment",
  httpMethod: "PUT",
};
export const DeleteInsuranceAttachmentPath: IPath = {
  path: "api/TourManagement/{InsuranceAttachmentId}/DeleteInsuranceAttachment",
  httpMethod: "DELETE",
};
export const AddTourAttachmentPath: IPath = {
  path: "api/TourManagement/{TourId}/AddTourAttachment",
  httpMethod: "POST",
};
export const GetAllTourAttachmentPath: IPath = {
  path: "api/TourManagement/{TourId}/GetAllTourAttachment",
  httpMethod: "GET",
};
export const GetTourAttachmentByIdPath: IPath = {
  path: "api/TourManagement/{TourAttachmentId}/GetTourAttachmentById",
  httpMethod: "GET",
};
export const DownloadTourAttachmentPath: IPath = {
  path: "api/TourManagement/{TourAttachmentId}/DownloadTourAttachment",
  httpMethod: "GET",
};
export const EditTourAttachmentPath: IPath = {
  path: "api/TourManagement/{TourAttachmentId}/EditTourAttachment",
  httpMethod: "PUT",
};
export const DeleteTourAttachmentPath: IPath = {
  path: "api/TourManagement/{TourAttachmentId}/DeleteTourAttachment",
  httpMethod: "DELETE",
};
export const AddRoutePath: IPath = {
  path: "api/TourManagement/{TourId}/AddRoute",
  httpMethod: "POST",
};
export const GetAllRoutePath: IPath = {
  path: "api/TourManagement/{TourId}/GetAllRoute",
  httpMethod: "GET",
};
export const GetRouteByIdPath: IPath = {
  path: "api/TourManagement/{RouteId}/GetRouteById",
  httpMethod: "GET",
};
export const EditRoutePath: IPath = {
  path: "api/TourManagement/{RouteId}/EditRoute",
  httpMethod: "PUT",
};
export const DeleteRoutePath: IPath = {
  path: "api/TourManagement/{RouteId}/DeleteRoute",
  httpMethod: "DELETE",
};
export const AddRouteRoomPath: IPath = {
  path: "api/TourManagement/{RouteId}/AddRouteRoom",
  httpMethod: "POST",
};
export const GetAllRouteRoomPath: IPath = {
  path: "api/TourManagement/{RouteId}/GetAllRouteRoom",
  httpMethod: "GET",
};
export const EditRouteRoomPath: IPath = {
  path: "api/TourManagement/{RouteRoomId}/EditRouteRoom",
  httpMethod: "PUT",
};
export const DeleteRouteRoomPath: IPath = {
  path: "api/TourManagement/{RouteRoomId}/DeleteRouteRoom",
  httpMethod: "DELETE",
};
export const AddRoutePlaceOfInterestPath: IPath = {
  path: "api/TourManagement/{RouteId}/AddRoutePlaceOfInterest",
  httpMethod: "POST",
};
export const GetAllRoutePlaceOfInterestPath: IPath = {
  path: "api/TourManagement/{RouteId}/GetAllRoutePlaceOfInterest",
  httpMethod: "GET",
};
export const EditRoutePlaceOfInterestPath: IPath = {
  path: "api/TourManagement/{RoutePlaceOfInterestId}/EditRoutePlaceOfInterest",
  httpMethod: "PUT",
};
export const DeleteRoutePlaceOfInterestPath: IPath = {
  path: "api/TourManagement/{RoutePlaceOfInterestId}/DeleteRoutePlaceOfInterest",
  httpMethod: "DELETE",
};
