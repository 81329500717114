import { Row } from "antd";
import styled from "styled-components";

export const CreateUserContainer = styled(Row)`
  padding-top: 32px;
  .header {
    border-bottom: 1px solid var(--shadow, #dadeec);
    display: flex;
    padding: 0 24px 24px 24px;
    align-items: center;
    gap: 10px;
    color: var(--text-3, #5d6881);
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
  }
  .content {
    border-radius: 20px;
    background: var(--0, #fff);

    /* 2 */
    box-shadow: 0px 4px 6px 0px rgba(113, 130, 183, 0.25);
    padding: 24px 32px;
  }
  .title {
    color: var(--text, #001377);
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .footer {
    border-top: 1px solid #dadeec;
    padding-top: 32px;
    padding-bottom: 16px;
  }
  /* .ant-input {
    &:focus,
    &:hover {
      border-inline-end-width: 0;
      box-shadow: none !important;
      background-color: transparent !important;
    }
  } */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text !important;

    transition: background-color 5000s ease-in-out 0s;
    -webkit-box-shadow: unset !important;
  }
`;

export const ModalContainer = styled.div`
  border: 1px solid #dadeec;
  padding: 48px;
  margin: 32px 0;
  border-radius: 8px;
  .ant-modal-content {
    padding: 24px 24px 0 24px;
  }
`;
