import { Row } from "antd";
import styled from "styled-components";

export const ManagementInsuranceContainer = styled(Row)`
  padding: 32px 0;
  .header {
    border-bottom: 1px solid var(--shadow, #dadeec);
    display: flex;
    padding: 0 24px 24px 24px;
    align-items: center;
    gap: 10px;
    color: var(--text-3, #5d6881);
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
  }
  .content {
    border-radius: 16px;
    background: var(--0, #fff);
    box-shadow: 2px 6px 16px 0px rgba(0, 0, 0, 0.18);
    padding: 24px 32px;
  }
  .view-details {
    display: flex;
    padding: 24px 16px;
    justify-content: flex-end;
    align-items: flex-start;
    align-content: flex-start;
    gap: 16px 24px;
    align-self: stretch;
    flex-wrap: wrap;
    border-radius: 8px;
    background: var(--color-Primary-Primary-100, #fef6f1);
    margin-bottom: 24px;
  }
  .title {
    color: var(--color-Light-Mode-Text-Title, #233069);
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
  }
  .sub-title {
    color: var(--color-Light-Mode-Text-Title, #233069);
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .detail-title {
    width: 160px;
    color: var(--color-Light-Mode-Text-Title, #233069);
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .second {
    margin: 15px 0;
  }
  .footer-box {
    width: 100%;
    display: inline-flex;
    justify-content: end;
    align-items: center;
  }
  .ant-input {
    color: #000000;
  }
  .add-hotel-form-title {
    color: var(--color-Light-Mode-Text-Title, #233069);
    text-align: right;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .simcard-table-title {
    color: var(--color-Light-Mode-Text-Primary-text, #1f1f1f);
    text-align: right;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .full-screen-loading {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
