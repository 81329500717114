import { FC } from "react";
import { Col } from "antd";
import CountryTable from "./components/CountryTable";
import AddCountry from "./components/AddCountry";
import { CountryContainer } from "./styles";
import { CountryProvider } from "./context";
import DataEntryHeader from "src/components/dataEntryHeader";
import useLanguage from "src/store/language";
import { Guard } from "src/components/Guard";
import { AddCountryPath } from "src/services/BasicInformation/baseGuardPath";

export const Country: FC = () => {
  const { words } = useLanguage();

  return (
    <Guard action={AddCountryPath}>
      <CountryProvider>
        <DataEntryHeader title={words.dataEntry.header.country} />
        <CountryContainer justify={"center"} gutter={[0, 24]}>
          <Col span={24} className="content">
            <AddCountry />
            <CountryTable />
          </Col>
        </CountryContainer>
      </CountryProvider>
    </Guard>
  );
};
