import { FC } from "react";
import { Col, Spin } from "antd";
import { ManagementTranslatorContainer } from "./style";
import { useManagementTranslatorData } from "./context";
import ViewTranslator from "./components/ViewTranslator";
import AddEditTranslator from "./components/AddEditTranslator";
import { Guard } from "src/components/Guard";
import {
  AddTranslatorPath,
  GetTranslatorPath,
} from "src/services/TourManagement/guardPath";

const ManagementTranslator: FC = () => {
  const {
    states: { mode, dataLoading },
  } = useManagementTranslatorData();

  return (
    <>
      {(mode === "add" || mode === "edit") && (
        <Guard action={[AddTranslatorPath, GetTranslatorPath]}>
          <ManagementTranslatorContainer justify={"center"} gutter={[0, 24]}>
            <Col xs={24} md={23} className="content">
              {dataLoading ? (
                <div className="flex w-full h-full justify-center items-center">
                  <Spin />
                </div>
              ) : (
                <AddEditTranslator />
              )}
            </Col>
          </ManagementTranslatorContainer>
        </Guard>
      )}
      {mode === "view" && (
        <ManagementTranslatorContainer justify={"center"} gutter={[0, 24]}>
          <Col xs={24} md={16} className="content">
            {dataLoading ? (
              <div className="flex w-full h-full justify-center items-center">
                <Spin />
              </div>
            ) : (
              <ViewTranslator />
            )}
          </Col>
        </ManagementTranslatorContainer>
      )}
    </>
  );
};

export default ManagementTranslator;
