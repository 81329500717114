import { FC, PropsWithChildren, useEffect, useState } from "react";
import { useCheckPermission } from "src/hook/checkPermission";
import { IPath } from "src/models/interfaces/IPath";
import _ from "lodash";

interface IProps extends PropsWithChildren {
  action: IPath | IPath[] | undefined;
}

export const Guard: FC<IProps> = ({ action, children }) => {
  const [hasPermission, setHasPermission] = useState<boolean>(false);
  const { checkActionPermission, checkRoutePermission } = useCheckPermission();

  useEffect(() => {
    if (_.isArray(action)) {
      setHasPermission(checkRoutePermission(action as IPath[]));
    } else {
      if (action) setHasPermission(checkActionPermission(action));
      else setHasPermission(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action]);
  return hasPermission ? <> {children} </> : <></>;
};
