import { FC, useEffect, useState } from "react";
import { useCreateUserContext } from "./context";
import { Button, Col, Flex, Form, Modal, Row, TreeSelect } from "antd";
import { CreateUserContainer, ModalContainer } from "./styles";
import { FormItem } from "src/components/UiKit/FormItem";
import InputUiKit from "src/components/UiKit/Input";
import useLanguage from "src/store/language";
import InputPassword from "src/components/UiKit/inputPassword";
import { Guard } from "src/components/Guard";
import { newUserPath } from "src/services/UserManagement/guardPath";

export const CreateUser: FC = () => {
  const {
    onFinish,
    onCancelConfirm,
    onConfirm,
    showConfirm,
    treeProps,
    confirmLoading,
    createUserForm,
  } = useCreateUserContext();

  const { words } = useLanguage();
  const [submittable, setSubmittable] = useState<boolean>(false);

  // Watch all values
  const values = Form.useWatch([], createUserForm);

  useEffect(() => {
    createUserForm
      ?.validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [createUserForm, values]);
  return (
    <Guard action={newUserPath}>
      <Form onFinish={onFinish} form={createUserForm}>
        <CreateUserContainer justify={"center"} gutter={[0, 24]}>
          <Col span={15} className="content">
            <Row gutter={[24, 16]}>
              <Col span={24} className="mb-[40px]">
                <div className="title">
                  {words.systemSettings.createUserFormTitle}
                </div>
              </Col>
              <Col xs={24} lg={12}>
                <FormItem
                  name={"userName"}
                  required
                  label={words.systemSettings.userName}
                  rules={[
                    { required: true, message: words.global.fieldRequired },
                  ]}
                >
                  <InputUiKit
                    placeholder={words.systemSettings.userNamePlaceHolder}
                  />
                </FormItem>
              </Col>
              <Col xs={24} lg={12}>
                <FormItem
                  name={"firstName"}
                  label={words.systemSettings.firstName}
                >
                  <InputUiKit
                    placeholder={words.systemSettings.firstNamePlaceHolder}
                  />
                </FormItem>
              </Col>
              <Col xs={24} lg={12}>
                <FormItem
                  name={"lastName"}
                  label={words.systemSettings.lastName}
                >
                  <InputUiKit
                    placeholder={words.systemSettings.lastNamePlaceHolder}
                  />
                </FormItem>
              </Col>
              <Col xs={24} lg={12}>
                <FormItem
                  name={"password"}
                  required
                  rules={[
                    { required: true, message: words.global.fieldRequired },
                  ]}
                  label={words.systemSettings.password}
                >
                  <InputPassword
                    placeholder={words.systemSettings.passwordNamePlaceHolder}
                    className="input-password"
                  />
                </FormItem>
              </Col>
              <Col xs={24} lg={12}>
                <FormItem
                  name={"roles"}
                  rules={[
                    { required: true, message: words.global.fieldRequired },
                  ]}
                  label={words.systemSettings.userRole}
                >
                  <TreeSelect
                    {...treeProps}
                    allowClear
                    className="selectOption"
                    treeCheckable
                    showSearch
                    treeNodeFilterProp="label"
                    placeholder={words.systemSettings.roleSelectPlaceHolder}
                  />
                </FormItem>
              </Col>
              <Col span={24} className="footer">
                <Flex justify="end" align="center" gap={8} className="w-full">
                  <Button
                    type="default"
                    className="w-[85px]"
                    onClick={onCancelConfirm}
                  >
                    {words.global.cancel}
                  </Button>
                  <Button
                    type="primary"
                    className="w-[85px]"
                    htmlType="submit"
                    disabled={!submittable}
                  >
                    {words.systemSettings.createButtonTitle}
                  </Button>
                </Flex>
              </Col>
            </Row>
          </Col>
        </CreateUserContainer>
      </Form>
      {/* <ConfirmModal
        open={showConfirm}
        onCancel={onCancelConfirm}
        onOk={onConfirm}
        text={words.systemSettings.createUserConfirmMessage}
        title={words.systemSettings.createUserConfirmTitle}
        okText={words.systemSettings.createButtonTitle}
        confirmLoading={confirmLoading}
      /> */}
      <Modal
        open={showConfirm}
        onCancel={onCancelConfirm}
        closeIcon={false}
        footer={false}
        title={words.systemSettings.createRoleConfirmTitle}
      >
        <ModalContainer>
          <p className="m-0 text-[16px] text-[#001377] font-medium">
            {words.systemSettings.createUserConfirmMessage}
          </p>
        </ModalContainer>
        <div className="border-0 border-t-[1px] border-solid border-[#dadeec] pt-[16px]">
          <Flex justify="end" gap={12} align="center" className="w-full">
            <Button
              type="text"
              className="bg-[#F5F5F5] text-[#4B4B4B] w-[85px]"
            >
              لغو
            </Button>
            <Button
              type="text"
              className="bg-[#F06614] text-[#FFF] w-[85px]"
              onClick={onConfirm}
              loading={confirmLoading}
            >
              تایید
            </Button>
          </Flex>
        </div>
      </Modal>
    </Guard>
  );
};
