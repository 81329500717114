import { IPath } from "src/models/interfaces/IPath";

export const SystemPaths: IPath[] = [
  {
    path: "api/System/AllActions",
    httpMethod: "GET",
  },
  {
    path: "api/System/NewRole",
    httpMethod: "POST",
  },
  {
    path: "api/System/AssignRoleToAction",
    httpMethod: "POST",
  },
  {
    path: "api/System/{ActionId}/RolesOfAction",
    httpMethod: "GET",
  },
];

export const AllActionsPath: IPath = {
  path: "api/System/AllActions",
  httpMethod: "GET",
};
export const NewRolePath: IPath = {
  path: "api/System/NewRole",
  httpMethod: "POST",
};
export const AssignRoleToActionPath: IPath = {
  path: "api/System/AssignRoleToAction",
  httpMethod: "POST",
};
export const RolesOfActionPath: IPath = {
  path: "api/System/{ActionId}/RolesOfAction",
  httpMethod: "GET",
};
