import { FC } from "react";
import AddManagementRoute from "./form/AddManagementRoute";
import ManagementRouteTable from "./table/ManagementRouteTable";
import ManagementRouteAddEditDataProvider from "./context";
import { useManagementRouteData } from "../../context";
import { ManagementRouteContainer } from "../../style";
import { Col } from "antd";
import { Guard } from "src/components/Guard";
import {
  AddRoutePath,
  GetAllRoutePath,
} from "src/services/TourManagement/guardPath";

const AddEditRoute: FC = () => {
  const {
    states: { mode },
  } = useManagementRouteData();

  return (
    <ManagementRouteAddEditDataProvider>
      <>
        {(mode === "add" || mode === "edit") && (
          <Guard action={[GetAllRoutePath, AddRoutePath]}>
            <ManagementRouteContainer justify={"center"} gutter={[0, 24]}>
              <Col xs={24} md={23} className="content">
                <AddManagementRoute />
                <ManagementRouteTable />
              </Col>
            </ManagementRouteContainer>
          </Guard>
        )}
        {mode === "view" && (
          <ManagementRouteContainer justify={"center"} gutter={[0, 24]}>
            <Col xs={24} md={23} className="content">
              <ManagementRouteTable />
            </Col>
          </ManagementRouteContainer>
        )}
      </>
    </ManagementRouteAddEditDataProvider>
  );
};

export default AddEditRoute;
