import { FC } from "react";
import { Col } from "antd";
import { ManagementSimcardAssignContainer } from "./style";
import { useManagementSimcardData } from "./context";
import AddEditSimcardDataProvider from "./components/add-edit/context";
import SimcardAddEditTabs from "./components/add-edit";
import ManagementSimcardTable from "./components/table/ManagementSimcardTable";
import { Guard } from "src/components/Guard";
import {
  AddSimCardTourTouristPath,
  GetAllSimCardTourTouristPath,
} from "src/services/TourManagement/guardPath";

const ManagementSimcardAssign: FC = () => {
  const {
    states: { mode },
  } = useManagementSimcardData();

  return (
    <>
      <AddEditSimcardDataProvider>
        <>
          {(mode === "add" || mode === "edit") && (
            <Guard
              action={[AddSimCardTourTouristPath, GetAllSimCardTourTouristPath]}
            >
              <ManagementSimcardAssignContainer
                justify={"center"}
                gutter={[0, 24]}
              >
                <Col xs={24} md={23} className="content">
                  <SimcardAddEditTabs />
                  <ManagementSimcardTable />
                </Col>
              </ManagementSimcardAssignContainer>
            </Guard>
          )}
          {mode === "view" && (
            <ManagementSimcardAssignContainer
              justify={"center"}
              gutter={[0, 24]}
            >
              <Col xs={24} md={16} className="content">
                <ManagementSimcardTable />
              </Col>
            </ManagementSimcardAssignContainer>
          )}
        </>
      </AddEditSimcardDataProvider>
    </>
  );
};

export default ManagementSimcardAssign;
