import { Row } from "antd";
import styled from "styled-components";

export const AddTouristInfoContainer = styled.div``;

export const AddNewTouristInfoContainer = styled(Row)`
  margin: 32px 0;
  padding: 32px 32px 24px 32px;
  border-radius: 20px;
  background: var(--color-Base-Color-White, #fff);

  /* 3 */
  box-shadow: 2px 6px 16px 0px rgba(0, 0, 0, 0.18);
  .rightShadow {
    box-shadow: -5px 0px 5px 0px rgba(90, 95, 118, 0.2);
  }
  .leftShadow {
    box-shadow: 5px 0px 5px 0px rgba(90, 95, 118, 0.2);
  }
  .ant-btn-default:disabled {
    background-color: #fad1b8;
    color: #fde8dc;
  }
  .ant-btn-default {
    background-color: #f06614;
    color: #fff;
  }
  .vCXFe {
    padding: 0px !important;
  }
  .ant-btn {
    padding: 0px !important;
  }
  .ant-btn-dashed:disabled {
    background-color: inherit;
    color: #fad1b8;
    border-style: solid;
    border-color: #fad1b8;
  }
  .ant-btn-dashed {
    border-color: #f06614;
    background-color: inherit;
    color: #f06614;
    border-style: solid;
  }
  .input-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  .input-file + label {
    width: 112px;
    height: 40px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 8px;
    background-color: #47b881;
    cursor: pointer;

    span {
      color: #ffffff;
      font-weight: 500;
      font-size: 12px;
    }
  }

  .ant-upload,
  .ant-upload-select {
    width: 112px !important;
    height: 40px !important;
    border: 0 !important;
    background-color: transparent !important;
  }
  .ant-upload-excel {
    width: 112px;
    height: 40px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 8px;
    background-color: #47b881;
    color: #ffffff;
    font-weight: 500;
    font-size: 12px;
  }

  .inquiry-btn {
    height: 51px;
    font-size: 12px;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .ant-input {
    background-color: #f5f5f5;
    border-radius: 12px;
    border: 0;
  }
  .ant-input:hover {
    border-inline-end-width: 0;
    box-shadow: none !important;
    background-color: #f5f5f5 !important;
  }

  .ant-input:focus {
    border-inline-end-width: 0;
    box-shadow: none !important;
    background-color: #f5f5f5 !important;
  }
  .ant-input:-webkit-autofill,
  .ant-input:-webkit-autofill:hover,
  .ant-input:-webkit-autofill:focus,
  .ant-input:-webkit-autofill:active {
    -webkit-box-shadow: unset !important;
  }
  .ant-btn-primary {
    background: #f06614;
  }
  .ant-input.ant-input-status-error {
    box-shadow: none !important;
    border: 0 !important;
    outline: none !important;
    background-color: #f5f5f5 !important;
  }
  .ant-input.ant-input-status-error:focus {
    border-inline-end-width: 0;
    box-shadow: none !important;
    background-color: #f5f5f5 !important;
  }
  .ant-input::placeholder {
    color: #8e8e8e;
    font-weight: 500;
    font-size: 12px;
  }
  .ant-input-group-wrapper {
    height: 51px;
  }
  .ant-input-group-wrapper {
    .ant-input,
    .ant-input-group-addon {
      height: 51px;
      .ant-btn {
        height: 51px;
        padding-left: 20px;
        padding-right: 20px;
        font-size: 12px;
      }
    }
  }
`;

export const ModalContentContainer = styled.div`
  margin-top: 30px;
  .description {
    padding: 48px;
    font-weight: 700;
    font-size: 18px;
    color: #000000;
  }
  .footer {
    width: 100%;
    border-top: 1px solid #dadeec;
    padding-top: 15px;
    margin-top: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  .save-btn {
    background-color: #f06614;
    color: #ffffff;
    font-weight: 700;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
  }
  .save-btn:hover {
    background-color: #f06614 !important;
    color: #ffffff !important;
    font-weight: 700;
    font-size: 12px;
  }
  .cancel {
    color: #8e8e8e;
    background-color: #f5f5f5;
    border-radius: 12px;
    margin-right: 10px;
    font-weight: 700;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
  }
  .material-icons {
    font-size: 12px;
  }
  .ant-table-wrapper .ant-table-cell,
  .ant-table-wrapper .ant-table-thead > tr > th {
    text-align: center;
  }
  .delete-btn {
    background-color: #f64c4c;
    color: #ffffff;
  }
  .delete-btn:hover {
    background-color: #f64c4c !important;
    color: #ffffff !important;
  }
  .add-btn {
    background-color: #f5f5f5;
    color: #8e8e8e;
    margin: 0 10px;
  }
  .add-btn:hover {
    background-color: #f5f5f5;
    color: #8e8e8e;
  }
  .ant-table-row {
    height: 100px;
  }

  .ant-table-row .ant-table-cell .ant-form-item .ant-row .ant-col {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .ant-form-item-control-input {
      height: 70%;
      width: 100%;
    }
    .ant-select,
    .ant-form-item-control-input-content {
      width: 100%;
    }
    .ant-form-item-explain-error {
      font-size: 10px;
    }
  }
`;

export const ModalHeaderContainer = styled.div`
  /* text-align: center; */
`;
